import React, { useEffect, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Container } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import Chart from 'react-google-charts'

import * as Requests from '../../utils/requests'

export const PermissionStats = () => {
  const [startDate, setstartDate] = useState(moment().subtract(3, 'days').toDate())
  const [locationPermGivenUsers, SetLocationPermGivenUsers] = useState({})
  const [noFotoUsers, SetNoFotoUsers] = useState({})
  const [nonTrLocationPermGivenUsers, SetNonTrLocationPermGivenUsers] = useState({})
  const [nonTrNoFotoUsers, SetNonTrNoFotoUsers] = useState({})

  const locationGivenQuery = useQuery({
    queryKey: ['users', 'location', 'given'],
    queryFn: () =>
      Requests.getPermissionStats({
        startDate,
        permission: 'location',
        exceptTr: false
      }),
    onSuccess: (res) => SetLocationPermGivenUsers(res),
    enabled: false
  })

  const nonTrLocationGivenQuery = useQuery({
    queryKey: ['nontr', 'location'],
    queryFn: () =>
      Requests.getPermissionStats({
        startDate,
        permission: 'location',
        exceptTr: true
      }),
    onSuccess: (res) => SetNonTrLocationPermGivenUsers(res),
    enabled: false
  })

  const noFotoQuery = useQuery({
    queryKey: ['users', 'noPhoto'],
    queryFn: () =>
      Requests.getPermissionStats({
        startDate,
        permission: 'photo',
        exceptTr: false
      }),
    onSuccess: (res) => SetNoFotoUsers(res),
    enabled: false
  })

  const nonTrNoFotoQuery = useQuery({
    queryFn: () =>
      Requests.getPermissionStats({
        startDate,
        permission: 'photo',
        exceptTr: true
      }),
    onSuccess: (res) => SetNonTrNoFotoUsers(res),
    enabled: false
  })

  const onChangeDate = (date) => {
    setstartDate(date)
  }

  useEffect(() => {
    locationGivenQuery.refetch()
    nonTrLocationGivenQuery.refetch()
    noFotoQuery.refetch()
    nonTrNoFotoQuery.refetch()
  }, [])

  useEffect(() => {
    if (startDate) {
      locationGivenQuery.refetch()
      nonTrLocationGivenQuery.refetch()
      noFotoQuery.refetch()
      nonTrNoFotoQuery.refetch()
    }
  }, [startDate])

  return (
    <Container fluid>
      <div className="col col-sm-col-md-3 mb-5">
        <label style={{ marginLeft: '10px' }}>Şu Tarihten Sonra</label>
        <div className="input-group">
          <ReactDatePicker
            className="form-control digits btn-pill"
            selected={startDate}
            preventOpenOnFocus
            dateFormat="dd/MM/yyyy"
            onChange={onChangeDate}
          />
        </div>
      </div>
      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Verildi', locationPermGivenUsers.userCount],
          ['Verilmedi', locationPermGivenUsers.total - locationPermGivenUsers.userCount]
        ]}
        options={{
          title: 'Lokasyon İzni',
          colors: ['green', 'red'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Verildi', nonTrLocationPermGivenUsers.userCount],
          ['Verilmedi', nonTrLocationPermGivenUsers.total]
        ]}
        options={{
          title: 'Türk Olmayan Lokasyon İzni',
          colors: ['green', 'red'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Var', noFotoUsers.userCount],
          ['Yok', noFotoUsers.total - noFotoUsers.userCount]
        ]}
        options={{
          title: 'Fotoğraf`a Sahip User',
          colors: ['green', 'red'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />
      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Var', nonTrNoFotoUsers.userCount],
          ['Yok', nonTrNoFotoUsers.total]
        ]}
        options={{
          title: 'Türk Olmayan Fotoğraf`a Sahip User',
          colors: ['green', 'red'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </Container>
  )
}
