import React, { useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'

import * as Requests from '../../utils/requests'

const RibbonsForClosedTicket = ({
  image,
  name,
  date,
  ticketID,
  message,
  allInform,
  closedTickets = () => {},
  setClosedTickets = () => {}
}) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)

  const reopenTicket = useMutation(Requests.reopenTicket, {
    onSuccess: async (response) => {
      setClosedTickets(closedTickets.filter((t) => t._id !== ticketID))
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const openTicketAgain = () => {
    reopenTicket.mutate({ ticketID })
  }

  const displayCloseReason = () => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('accessToken')
    }
    setVerticalcenter(true)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '450px' }}>
            <CardBody>
              <div
                className="ribbon ribbon-danger ribbon-right"
                style={{
                  borderRadius: '10px',
                  marginRight: '15px',
                  marginTop: '7px'
                }}
              >
                Kapalı
              </div>
              <img
                src={image}
                alt=""
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              {allInform.creator?.profile?.bio && (
                <p>
                  <b>bio: </b>
                  {allInform.creator?.profile?.bio}
                </p>
              )}
              <p>{moment(date).utc(true).local(true).format('DD.MM.YYYY - HH:mm')}</p>
              <p>{message}</p>
              <Button
                className="btn-air-primary"
                color="primary"
                onClick={openTicketAgain}
                size="sm"
              >
                Tekrar Aç
              </Button>
              <Button
                className="btn-air-primary"
                color="danger"
                onClick={displayCloseReason}
                size="sm"
                style={{ marginLeft: '15px' }}
              >
                İşlem Bilgisi
              </Button>
              <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
                <ModalHeader toggle={Verticalcentermodaltoggle}>İşlem Detayı</ModalHeader>
                <ModalBody>{allInform.operation?.info}</ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={Verticalcentermodaltoggle}>
                    Kapat
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsForClosedTicket
