import React, { useState, Fragment, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Pagination
} from 'reactstrap'

import * as Requests from '../../utils/requests'
import PaginationCustom from './PaginationCustom'
import RibbonsForClosedReport from './RibbonsCustomForClosedReports'
import RibbonsForOpenReport from './RibbonsCustomForOpenReports'

const TabLineCustomForReports = () => {
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1')
  const [openReports, setOpenReports] = useState([])
  const [closedReports, setClosedReports] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [pageCountClosed, setPageCountClosed] = useState(1)
  const [paginationActive, setPaginationActive] = useState({
    next: {
      page: 1,
      limit: 21
    }
  })
  const [paginationClosed, setPaginationClosed] = useState({
    next: {
      page: 1,
      limit: 21
    }
  })

  const getOpenReports = useMutation(Requests.getOpenReports, {
    onSuccess: async (response) => {
      setOpenReports(response.tickets)
      setPageCount(response.totalCount / response.pagination.next.limit)
      setPaginationActive(response.pagination)
    }
  })

  const getClosedReports = useMutation(Requests.getClosedReports, {
    onSuccess: async (response) => {
      setClosedReports(response.tickets)
      setPageCountClosed(response.totalCount / response.pagination.next.limit)
      setPaginationClosed(response.pagination)
    }
  })

  const getNewOpenReports = useMutation(Requests.getNewOpenReports, {
    onSuccess: async (response) => {
      setOpenReports(response.tickets)
      setPaginationActive(response.pagination)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  const getNewClosedReports = useMutation(Requests.getNewClosedReports, {
    onSuccess: async (response) => {
      setClosedReports(response.tickets)
      setPaginationClosed(response.pagination)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  useEffect(() => {
    getOpenReports.mutate({
      nextPage: paginationActive.next.page,
      nextLimit: paginationActive.next.limit
    })

    getClosedReports.mutate({
      nextPage: paginationClosed.next.page,
      nextLimit: paginationClosed.next.limit
    })
  }, [])

  const getNewPage = (index) => {
    getNewOpenReports.mutate({ index, nextLimit: paginationActive.next.limit })
  }

  const createPage = (page) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationActive.next.page - 1}
          pageNumb={index}
          getNewPage={() => getNewPage(index)}
        />
      )
    }

    return <div className="d-flex justify-content-center">{items}</div>
  }

  const getNewPageClosed = (index) => {
    getNewClosedReports.mutate({
      index,
      nextLimit: paginationClosed.next.limit
    })
  }

  const createPageClosed = (page) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationClosed.next.page - 1}
          pageNumb={index}
          getNewPage={() => getNewPageClosed(index)}
        />
      )
    }

    return <div className="d-flex justify-content-center">{items}</div>
  }

  return (
    <Container fluid style={{ width: '95%' }}>
      <Row>
        <Col>
          <Card style={{ width: '90%' }}>
            <CardBody>
              <Nav className="border-tab nav-primary" tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '1' ? 'active' : ''}
                    onClick={() => setPrimarycolorLineTab('1')}
                  >
                    <i className="icofont icofont-page" />
                    Açık Raporlar
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '2' ? 'active' : ''}
                    onClick={() => setPrimarycolorLineTab('2')}
                  >
                    <i className="icofont icofont-tick-boxed" />
                    Kapalı Raporlar
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={PrimarycolorLineTab}>
                <TabPane className="fade show" tabId="1">
                  <div className="row row-cols-auto">
                    {openReports.map((r) => (
                      <RibbonsForOpenReport
                        report={r}
                        openReports={openReports}
                        setOpenReports={setOpenReports}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">{createPage(pageCount)}</ul>
                    </Pagination>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row row-cols-auto">
                    {closedReports.map((cr) => (
                      <RibbonsForClosedReport
                        report={cr}
                        closedReports={closedReports}
                        setClosedReports={setClosedReports}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">
                        {createPageClosed(pageCountClosed)}
                      </ul>
                    </Pagination>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TabLineCustomForReports
