import React from "react";
import { Col } from "reactstrap";

function LoaderCustom() {
  return (
    <div>
      <Col md="center">
        <div className="loader-box">
          <div className="loader-9"></div>
        </div>
      </Col>
    </div>
  );
}

export default LoaderCustom;
