import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

import * as Requests from '../../utils/requests'

import pp from '../../assets/images/dummypp.png'

const RibbonsForClosedReport = ({ report, closedReports, setClosedReports }) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const [VaryingContentone, setVaryingContentone] = useState(false)
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone)
  const [closeInfo, setCloseInfo] = useState('')
  const [biog, setBiog] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const reopenReportMutate = useMutation(Requests.reopenTicket, {
    onSuccess: async (response) => {
      setClosedReports(closedReports.filter((r) => r._id !== report._id))
      // toast.success(`Başarılı `, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    },
    onError: (e) => {
      window.location.reload()
      // toast.error(`Hata Meydana Geldi ${e}`, {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
    }
  })

  const reOpenReport = () => {
    reopenReportMutate.mutate({ ticketID: report._id })
  }

  const seeBioDetails = (bio) => {
    setBiog(bio)
    setVerticalcenter(true)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '500px' }}>
            <CardBody>
              <div
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#ffd6d6',
                  color: 'red',
                  marginRight: '10px'
                }}
                className="ribbon ribbon-right"
              >
                Kapalı
              </div>
              <div className="ribbon ribbon-left">
                <div>
                  <h6 style={{ color: '#5e43be' }}>{'Tarih : '}</h6>
                  <b
                    className="position-absolute"
                    style={{
                      color: 'black',
                      left: '75px',
                      top: '-4px',
                      width: '150px'
                    }}
                  >
                    {moment(report.date).utc(true).local(true).format('DD.MM.YYYY - HH:mm', true)}
                  </b>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-sm-6 span 4 border rounded col-xs- container text-center h-40 d-inline-block p-4"
                  style={{
                    borderColor: 'gray',
                    borderWidth: '10px'
                  }}
                >
                  <img
                    src={report?.creator?.extra?.profilePic || pp}
                    alt=""
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50px'
                    }}
                  />
                  <div>
                    <b style={{ color: 'gray' }}>Kullanıcı Adı</b>
                    <h6 style={{ marginTop: '5x' }}>{report?.creator?.extra?.name || 'Karma'}</h6>
                    <b style={{ color: 'gray' }}>Biyografi</b>
                    <div>
                      <p
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          lineHeight: '1.5em',
                          height: '3em',
                          fontSize: '12px',
                          maxLines: 2,
                          color: 'black'
                        }}
                      >
                        {report?.creator?.profile?.bio || ''}
                      </p>
                      {report?.creator?.profile?.bio?.length > 46 && (
                        <b
                          style={{ color: '#5e43be', cursor: 'pointer' }}
                          onClick={() => seeBioDetails(report?.creator?.profile?.bio)}
                        >
                          Devamını Gör
                        </b>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="col-sm-6  span 4 border rounded col-xs- container text-center h-40 d-inline-block p-4 "
                  style={{
                    borderColor: 'gray',
                    borderWidth: '10px'
                  }}
                >
                  <img
                    src={report?.reportedUser?.extra?.profilePic || pp}
                    alt=""
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50px'
                    }}
                  />
                  <div>
                    <b style={{ color: 'gray' }}>Kullanıcı Adı</b>
                    <h6 style={{ marginTop: '5px' }}>
                      {report?.reportedUser?.extra?.name || 'Karma'}
                    </h6>
                    <b style={{ color: 'gray' }}>Biyografi</b>
                    <p
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        lineHeight: '1.5em',
                        height: '3em',
                        fontSize: '12px',
                        maxLines: 2,
                        color: 'black'
                      }}
                    >
                      {report?.reportedUser?.profile?.bio || ''}
                    </p>

                    <div>
                      {report?.reportedUser?.profile?.bio?.length > 46 && (
                        <b
                          style={{
                            color: '#5e43be',
                            cursor: 'pointer'
                          }}
                          onClick={() => seeBioDetails(report?.reportedUser?.profile?.bio)}
                        >
                          Devamını Gör
                        </b>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-md-4 border rounded mt-2 mb-3 w-100 h-50 d-inline-block">
                  <b style={{ color: 'gray' }}>Ticket Detay</b>
                  <p style={{ color: 'black' }}>{report.message}</p>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center ">
                <div>
                  <Button className="btn-air-primary" color="success" onClick={reOpenReport}>
                    Tekrar Aç
                  </Button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={VaryingContentonetoggle}
                  >
                    işlem Bilgisi
                  </button>
                </div>
              </div>
              <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle}>
                <ModalHeader toggle={Verticalcentermodaltoggle}>Biyografi Detayı</ModalHeader>
                <ModalBody>
                  <h5>{biog}</h5>
                </ModalBody>
              </Modal>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>İşlem Detayı</ModalHeader>
                <ModalBody>
                  <h5>{report.response}</h5>
                </ModalBody>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsForClosedReport
