import React, { memo, useCallback } from 'react'

import classNames from 'classnames'
import classNamesBind from 'classnames/bind'

import styles from './styles.module.css'

import placeholderUser from '../../../assets/images/placeholder-user.png'

const cx = classNamesBind.bind(styles)

const BanTicketCardComponent = ({ id, name, thumbnail, message, status, onClick = () => {} }) => {
  const onClickHandler = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  return (
    <div
      className={classNames(styles.container, styles.relative)}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
    >
      <div className={classNames(styles.flex, styles.fill, styles['flex-column'])}>
        <div className={styles.topContainer}>
          <img src={thumbnail || placeholderUser} alt={`ban${id}`} className={styles.image} />
          <p
            className={classNames(
              styles.text,
              styles['ml-1'],
              styles['f-rubik'],
              styles['fs-1'],
              styles['fw-5']
            )}
          >
            {name}
          </p>
        </div>
        <p className={classNames(styles.text, styles['fw-5'], styles['f-rubik'], styles['mt-1'])}>
          Mesajı
        </p>
        <p className={classNames(styles.text)}>{message}</p>
      </div>
      <div
        className={classNames(
          styles.flex,
          styles['flex-column'],
          styles['space-between'],
          styles['align-end']
        )}
      >
        <div
          className={cx(
            {
              'bg-red': status === 'Open',
              'bg-purple': status === 'Closed'
            },
            styles['br-1'],
            styles['pv-1'],
            styles['ph-2']
          )}
        >
          <p className={classNames(styles.text, styles['f-rubik'], styles['c-white'])}>{status}</p>
        </div>
        <i
          className={classNames('icofont icofont-rounded-right', styles['fs-5'], styles['c-gray'])}
        />
      </div>
    </div>
  )
}

export const BanTicketCard = memo(BanTicketCardComponent)
