import React, { useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { Button, ButtonGroup, Image, ToggleButton } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Container, Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody } from 'reactstrap'

import * as Requests from '../../utils/requests'
import ModalNoInput from './ModalNoInput'
import ModalWithInputDate from './ModalWithInputDate'

export const RibbonsCustomForLastCreatedUsers = ({
  image,
  name,
  bio,
  birthInfoAsDate,
  status,
  lastActivityDate,
  country,
  userId,
  createdAt
}) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)
  const [requestModal, setRequestModal] = useState(false)
  const VerticalRequestModal = () => setRequestModal(!requestModal)

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [banModal, setBanModal] = useState(false)
  const [removeShuffleModal, setRemoveSuffleModal] = useState(false)

  const [startChatModal, setStartChatModal] = useState(false)
  const [giveVenusModal, setGiveVenusModal] = useState(false)

  const [startDate, setstartDate] = useState(new Date())
  const [banReason, setBanReason] = useState('')

  const [userInfo, setUserInfo] = useState({})
  const [adminUsers, setAdminUsers] = useState([])
  const [radioValue, setRadioValue] = useState(null)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const startChatMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      setStartChatModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setStartChatModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const banFromShuffleMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
      setRemoveSuffleModal(false)
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      setRemoveSuffleModal(false)
    }
  })

  const normalBanMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      setBanModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setBanModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const deviceBanMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      setBanModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setBanModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const deleteBioMutation = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const locationBanMutation = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const getUserInfosMutation = useMutation(Requests.getUserInfos, {
    onSuccess: async (response) => {
      setUserInfo(response)
    },
    onError: (e) => {}
  })

  const givePremiumMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const getAdminUsersMutation = useMutation(Requests.getAdminUsers, {
    onSuccess: async (response) => {
      setAdminUsers(response)
    },
    onError: (e) => {}
  })

  const sendRequestWithAdminMutation = useMutation(Requests.sendRequestWithAdmin, {
    onSuccess: async (response) => {
      setRequestModal(false)
    },
    onError: (e) => {}
  })

  const banUserModal = () => {
    setBanModal(true)
    setDropdownOpen(false)
  }

  const banUser = () => {
    setBanModal(false)
    setDropdownOpen(false)
  }

  const banFromShuffleModal = () => {
    setRemoveSuffleModal(true)
    setDropdownOpen(false)
  }

  const deleteUserBio = () => {
    setDropdownOpen(false)
    deleteBioMutation.mutate({ userId, action: 'deleteBio' })
    setDropdownOpen(false)
  }

  const locationBan = () => {
    setDropdownOpen(false)
    locationBanMutation.mutate({ userId, action: 'locationBan' })
    setDropdownOpen(false)
  }

  const banFromShuffle = () => {
    banFromShuffleMutate.mutate({ userId, action: 'shuffleBan' })
    setDropdownOpen(false)
  }

  const normalBan = () => {
    normalBanMutate.mutate({
      userId,
      banReason,
      banDuration: startDate,
      action: 'ban'
    })
    setDropdownOpen(false)
  }

  const deviceBan = () => {
    deviceBanMutate.mutate({ userId, action: 'perma', banReason })
    setDropdownOpen(false)
  }

  const startChat = () => {
    startChatMutate.mutate({ userId, action: 'startChat' })
    setDropdownOpen(false)
  }

  const givePremium = () => {
    setGiveVenusModal(false)
    givePremiumMutate.mutate({ userId, action: 'premium' })
    setDropdownOpen(false)
  }

  const giveVenusModalHandler = () => {
    setGiveVenusModal(true)
    setDropdownOpen(false)
  }

  const getUserInfos = () => {
    setVerticalcenter(true)
    getUserInfosMutation.mutate(userId)
    setDropdownOpen(false)
  }

  const openFakeRequestModal = () => {
    getAdminUsersMutation.mutate()
    setRequestModal(true)
    setDropdownOpen(false)
  }

  const sendMatchRequest = () => {
    if (!radioValue) return
    sendRequestWithAdminMutation.mutate({ userId: radioValue, targetUserId: userId })
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '500px' }}>
            <CardBody>
              <Button
                variant="info"
                className="ribbon ribbon-right"
                style={{
                  marginRight: '10px'
                }}
                onClick={getUserInfos}
              >
                Ek Bilgiler
              </Button>

              <img
                src={image}
                alt=""
                style={{
                  width: '120px',
                  height: '120px',
                  marginBottom: '20px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              {bio && (
                <p>
                  {`Bio: `}
                  <b>{bio}</b>
                </p>
              )}

              <p>
                {`Yaş: `}
                <b>{moment().diff(birthInfoAsDate, 'years')}</b>
              </p>
              <p>
                {`Doğum Tarihi: `}
                <b>{moment(birthInfoAsDate).format('DD/MM/yyyy')}</b>
              </p>

              <p>
                {`Son Aktiflik: `}
                <b>{moment(lastActivityDate).format('DD.MM.yyyy HH:mm')}</b>
              </p>

              <p>
                {`Kayıt tarihi: `}
                <b>{moment(createdAt).format('DD.MM.yyyy HH:mm')}</b>
              </p>

              <p>
                {`Status: `}
                <b>{status}</b>
              </p>

              <p>
                {`Ülke: `}
                <b>{country}</b>
              </p>

              <div className="d-flex justify-content-around">
                <Button variant="success" onClick={startChat}>
                  Chat Başlat
                </Button>
                <div className="btn-group dropup">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggle}
                  >
                    işlemler
                  </button>
                  <div
                    className={`dropdown-menu${
                      dropdownOpen ? '-show' : ''
                    } position-absolute border `}
                    style={{
                      bottom: '35px',
                      backgroundColor: 'white',
                      borderColor: 'gray',
                      borderWidth: '10px'
                    }}
                  >
                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={giveVenusModalHandler}
                    >
                      Premium Ver
                    </p>

                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={banUserModal}
                    >
                      Kullanıcıyı Uzaklaştır
                    </p>

                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={banFromShuffleModal}
                    >
                      Shuffle'dan Kaldır
                    </p>

                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={deleteUserBio}
                    >
                      Bio Sil
                    </p>

                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={locationBan}
                    >
                      Location Ban
                    </p>

                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={openFakeRequestModal}
                    >
                      Eşleşme isteği Gönder
                    </p>
                  </div>
                </div>
              </div>

              <ModalWithInputDate
                isOpen={banModal}
                setIsOpen={setBanModal}
                title="Kullanıcıyı Uzaklaştır"
                applyHandler={banUser}
                startDate={startDate}
                setstartDate={setstartDate}
                setInput={setBanReason}
                ban={normalBan}
                deviceBan={deviceBan}
              />

              <ModalNoInput
                isOpen={removeShuffleModal}
                setIsOpen={setRemoveSuffleModal}
                title="Shuffleden Kaldır"
                desc={"Kullanıcıyı shuffle'den kaldırmak istiyor musunuz?"}
                applyHandler={banFromShuffle}
              />

              <ModalNoInput
                desc="Kullanıcıya venüs üyeliği hediye eder"
                applyHandler={givePremium}
                title="Venüs Üyelik ver ?"
                setIsOpen={setGiveVenusModal}
                isOpen={giveVenusModal}
              />

              <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle}>
                <ModalHeader toggle={Verticalcentermodaltoggle}>
                  Ek Kullanıcı İstatistikleri
                </ModalHeader>
                <ModalBody>
                  <h5>{`Gönderilen İstek Sayısı: ${userInfo.sentMatchRequestCount}`}</h5>
                  <h5>{`Gelen İstek Sayısı: ${userInfo.gotMatchRequestCount}`}</h5>
                  <h5>{`Red Ettiği Kullanıcı Sayısı: ${userInfo.deniedMatchRequestCount}`}</h5>
                  <h5>{`Red Edilme Sayısı: ${userInfo.deniedByUserMatchRequestCount}`}</h5>
                  <h5>{`Aktif Chat: ${userInfo.activeChat}`}</h5>
                  <h5>{`App Launch Count: ${userInfo.appLaunchCount}`}</h5>
                  {userInfo.extraPhotos && userInfo.extraPhotos.length > 0 && (
                    <h5>Extra Fotolar</h5>
                  )}
                  {userInfo.extraPhotos &&
                    userInfo.extraPhotos.length > 0 &&
                    userInfo.extraPhotos.map((photo) => (
                      <Image
                        src={photo}
                        width={100}
                        height={100}
                        style={{ marginLeft: '20px', marginTop: '10px' }}
                      />
                    ))}
                </ModalBody>
              </Modal>

              <Modal isOpen={requestModal} toggle={VerticalRequestModal}>
                <ModalHeader toggle={VerticalRequestModal}>Eşleşme isteği Gönder</ModalHeader>
                <ModalBody>
                  <div className="d-flex flex-column">
                    <ButtonGroup>
                      {adminUsers.map((radio, idx) => {
                        if (radio.user.name === 'Karma Destek') return null

                        return (
                          <div className="container">
                            <div className="col d-flex flex-column align-items-center">
                              <Image
                                src={radio?.user?.extra?.profilePic}
                                roundedCircle
                                width={75}
                                height={75}
                                style={{ marginBottom: '10px' }}
                              />
                              <ToggleButton
                                key={radio.user._id}
                                id={`radio-${idx}`}
                                type="radio"
                                variant={
                                  radio?.user?.extra?.gender === 'Male'
                                    ? 'outline-success'
                                    : 'outline-danger'
                                }
                                name="radio"
                                value={radio.user._id}
                                checked={radioValue === radio.user._id}
                                onChange={(e) => setRadioValue(e.currentTarget.value)}
                              >
                                {radio.user.name}
                              </ToggleButton>
                            </div>
                          </div>
                        )
                      })}
                    </ButtonGroup>
                    <Button onClick={sendMatchRequest}>Gönder</Button>
                  </div>
                </ModalBody>
              </Modal>

              <ModalNoInput
                isOpen={startChatModal}
                setIsOpen={setStartChatModal}
                title="Kullanıcı ile sohbet başlat ?"
                desc="Kullanıcıyla sohbet başlatır"
                applyHandler={startChat}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}
