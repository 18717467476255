import React, { Fragment, useCallback, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'
import { Button, Pagination } from 'reactstrap'

import PaginationCustom from '../components/custom/PaginationCustom'
import RibbonsCustomForBan from '../components/custom/RibbonsCustomForBan'
import { RibbonsCustomForLastCreatedUsers } from '../components/custom/RibbonsCustomForLastCreatedUsers'
import { RibbonsCustomForPossibleFakeUsers } from '../components/custom/RibbonsCustomForPossibleFakeUsers'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

export const PossibleFakeUsers = ({ children, classNames, ...rest }) => {
  const [users, setUsers] = useState([])
  const [lastTimestamp, setLastTimestamp] = useState(new Date())

  const [paginationActive, setPaginationActive] = useState(1)

  const mutation = useMutation(Requests.getPossibleFakeUsers, {
    onSuccess: async (response) => {
      setUsers(response)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  useEffect(() => {
    mutation.mutate({
      timestamp: new Date(),
      page: 0,
      limit: 20
    })
    setPaginationActive(1)
    setLastTimestamp(new Date())
  }, [])

  const getNewPage = (index) => {
    setPaginationActive(index)
    mutation.mutate({ timestamp: lastTimestamp, limit: 20, page: index - 1 })
  }

  const createPage = (page) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationActive}
          pageNumb={index}
          getNewPage={() => getNewPage(index)}
        />
      )
    }

    return (
      <div key={page} className="d-flex justify-content-center">
        {items}
      </div>
    )
  }

  return (
    <>
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Muhtemel Fake Kullanıcılar" />
              <div className="row row-cols-auto">
                {users.map((e) => (
                  <RibbonsCustomForPossibleFakeUsers
                    key={e._id}
                    image={e.profilePic || null}
                    name={e.name}
                    bio={e.bio}
                    birthInfoAsDate={e.birthInfoAsDate}
                    status={e.status}
                    lastActivityDate={e.lastActivityDate}
                    country={e.country}
                    userId={e._id}
                    createdAt={e.createdAt}
                    deviceModel={e.deviceModel}
                    location={e.location}
                    appLaunchCount={e.appLaunchCount}
                    birthCity={e.birthCity.city?.name}
                  />
                ))}
              </div>
              <div className="d-flex justify-content-center row-cols-auto">
                <Pagination aria-label="...">
                  <ul className="pagination pagination-primary">{createPage(20)}</ul>
                </Pagination>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
