import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import { Input, InputGroup, InputGroupText, Row } from 'reactstrap'

import RibbonsForClosedReport from '../components/custom/RibbonsCustomForClosedReports'
import RibbonsForClosedTicket from '../components/custom/RibbonsCustomForClosedTicket'
import RibbonsForOpenReport from '../components/custom/RibbonsCustomForOpenReports'
import RibbonsForOpenTicket from '../components/custom/RibbonsCustomForOpenTicket'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

import karmapp from '../assets/images/dummypp.png'

const AppLayout = ({ children, classNames, ...rest }) => {
  const [searchInput, setInput] = useState('')
  const [result, setResult] = useState([])

  const mutation = useMutation(Requests.searchTicket, {
    onSuccess: async (response) => {
      setResult(response)
      console.log(response)
    },
    onError: (e) => {
      console.log('e : ', e)
    }
  })

  const searchTicket = () => {
    mutation.mutate({ searchInput })
  }

  return (
    <>
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Arama" />
              <Row className="col-sm-6 col-md-5 offset-md-2 col-lg-6 offset-lg-0">
                <InputGroup style={{ width: '70%' }}>
                  <Input
                    className="form-control-plaintext"
                    type="search"
                    placeholder="Ticket id ve ya telefon numarası ile arama"
                    onChange={(e) => setInput(e.target.value)}
                    value={searchInput}
                    style={{
                      backgroundColor: 'white',
                      borderColor: '#b2b2ff',
                      borderWidth: '1px',
                      borderTopLeftRadius: '10px',
                      borderBottomLeftRadius: '10px',
                      padding: '5px'
                    }}
                  />
                  <InputGroupText
                    className="btn btn-primary"
                    onClick={searchTicket}
                    style={{
                      borderTopRightRadius: '10px',
                      borderBottomRightRadius: '10px'
                    }}
                  >
                    Ara
                  </InputGroupText>
                </InputGroup>
                <div className="row row-cols-auto w-90 mt-5 ">
                  {result.length > 0 &&
                    result.map((t) => {
                      if (t.entityType === 'TICKET')
                        return t.status === 'Open' ? (
                          <RibbonsForOpenTicket
                            image={t.creator?.extra.thumbnail || karmapp}
                            key={t._id}
                            name={t.creator?.name}
                            date={t.date}
                            message={t.message}
                            ticketID={t._id}
                            allInform={t}
                          />
                        ) : (
                          <RibbonsForClosedTicket
                            image={t.creator?.extra.thumbnail || karmapp}
                            name={t.creator?.name}
                            message={t.message}
                            date={t.date}
                            allInform={t}
                            ticketID={t._id}
                          />
                        )
                      if (t.entityType === 'REPORT')
                        return t.status === 'Open' ? (
                          <RibbonsForOpenReport report={t} />
                        ) : (
                          <RibbonsForClosedReport report={t} />
                        )
                    })}
                </div>
              </Row>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AppLayout
