import React, { Fragment, useEffect, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import moment, { lang } from 'moment'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup'
import { Lock, Trash2, User, UserMinus, UserPlus, Users } from 'react-feather'
import { CardBody } from 'reactstrap'

import * as Requests from '../../utils/requests'

const UsersCountryAndGender = ({ language }) => {
  const { data: maleData } = useQuery({
    queryKey: ['users', 'count', 'male', language],
    queryFn: () => Requests.getCountByGenderAndLanguage({ gender: 'Male', language })
  })
  const { data: femaleData } = useQuery({
    queryKey: ['users', 'count', 'female', language],
    queryFn: () => Requests.getCountByGenderAndLanguage({ gender: 'Female', language })
  })
  const { data: languageData } = useQuery({
    queryKey: ['users', 'count', language],
    queryFn: () => Requests.getCountByLanguage({ language })
  })
  const { data: freezedFemaleData } = useQuery({
    queryKey: ['users', 'count', 'freezed', 'female', language],
    queryFn: () => Requests.getFreezedCountByGender({ gender: 'Female', language })
  })
  const { data: freezedMaleData } = useQuery({
    queryKey: ['users', 'count', 'freezed', 'male', language],
    queryFn: () => Requests.getFreezedCountByGender({ gender: 'Male', language })
  })
  const { data: deletedFemaleData } = useQuery({
    queryKey: ['users', 'count', 'deleted', 'female', language],
    queryFn: () => Requests.getDeletedCountByGender({ gender: 'Female', language })
  })
  const { data: deletedMaleData } = useQuery({
    queryKey: ['users', 'count', 'deleted', 'male', language],
    queryFn: () => Requests.getDeletedCountByGender({ gender: 'Male', language })
  })
  const { data: createdData } = useQuery({
    queryKey: ['users', 'count', 'range', 'week'],
    queryFn: () =>
      Requests.getDailyCreatedAt({
        dateMax: moment().toISOString(),
        dateMin: moment().subtract(7, 'days').toISOString(),
        language
      })
  })

  return (
    <Container fluid>
      <Row>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="bg-primary b-r-4">
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Users />
                </div>
                <div className="media-body">
                  <span className="m-0">Toplam Kullanıcı</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={languageData} />
                  </h4>
                  <User className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="b-r-4" style={{ backgroundColor: '#90caf9', color: 'white' }}>
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Users />
                </div>
                <div className="media-body">
                  <span className="m-0">Toplam Erkek</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={maleData} />
                  </h4>
                  <User className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="b-r-4" style={{ backgroundColor: '#FF69B4', color: 'white' }}>
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Users />
                </div>
                <div className="media-body">
                  <span className="m-0">Toplam Kadın</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={femaleData} />
                  </h4>
                  <User className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="b-r-4" style={{ backgroundColor: '#536dfe', color: 'white' }}>
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Lock />
                </div>
                <div className="media-body">
                  <span className="m-0">Erkek Freezed</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={freezedMaleData} />
                  </h4>
                  <Lock className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="b-r-4" style={{ backgroundColor: '#536dfe', color: 'white' }}>
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Lock />
                </div>
                <div className="media-body">
                  <span className="m-0">Kadın Freezed</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={freezedFemaleData} />
                  </h4>
                  <Lock className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="bg-danger b-r-4">
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Trash2 />
                </div>
                <div className="media-body">
                  <span className="m-0">Erkek Deleted</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={deletedMaleData} />
                  </h4>
                  <Trash2 className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="6" xl="3" lg="6">
          <Card className="o-hidden">
            <CardBody className="bg-danger b-r-4">
              <div className="media static-top-widget">
                <div className="align-self-center text-center">
                  <Trash2 />
                </div>
                <div className="media-body">
                  <span className="m-0">Kadın Deleted</span>
                  <h4 className="mb-0 counter">
                    <CountUp end={deletedFemaleData} />
                  </h4>
                  <Trash2 className="icon-bg" />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default UsersCountryAndGender
