import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'
import ModalForChat from './ModalForChat'
import ModalNoInput from './ModalNoInput'
import ModalWithInput from './ModalWithInput'
import ModalWithInputDate from './ModalWithInputDate'

import pp from '../../assets/images/dummypp.png'

const RibbonsForOpenReport = ({ report, openReports, setOpenReports }) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const [VaryingContentone, setVaryingContentone] = useState(false)
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone)
  const [closeInfo, setCloseInfo] = useState('')
  const [biog, setBiog] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const [banModal, setBanModal] = useState(false)
  const [removeShuffleModal, setRemoveSuffleModal] = useState(false)
  const [warnModal, setWarnUserModal] = useState(false)
  const [showChatModal, setShowChatModal] = useState(false)
  const [startChatModal, setStartChatModal] = useState(false)
  const [getChatMessages, setChatMessages] = useState([])
  const [startDate, setstartDate] = useState(new Date())
  const [banReason, setBanReason] = useState('')

  const startChatMutate = useMutation(Requests.startChat, {
    onSuccess: async (response) => {
      setStartChatModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setStartChatModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const closeTicketMutate = useMutation(Requests.closeTicket, {
    onSuccess: async (response) => {
      setVaryingContentone(false)
      setOpenReports(openReports.filter((r) => r._id !== report._id))
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      setVaryingContentone(false)
    }
  })

  const showChatMutate = useMutation(Requests.getChat, {
    onSuccess: async (response) => {
      const messages = []

      if (response?.messages === undefined) {
        return toast.info(`Chat geçmişi bulunmamakta`, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      response.messages.map((message) => {
        const isUser1 = response.user1._id === message.sender

        const username = isUser1 ? response.user1?.name : response.user2?.name

        const textContent = `${username}: ${message.text}`

        messages.push(`${textContent}\n`)
        setChatMessages(messages)
      })
      setShowChatModal(true)
    },
    onError: (e) => {
      setShowChatModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const warnUserMutate = useMutation(Requests.warnUser, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
      setWarnUserModal(false)
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      setWarnUserModal(false)
    }
  })

  const banFromShuffleMutate = useMutation(Requests.banFromShuffle, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
      setRemoveSuffleModal(false)
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      setRemoveSuffleModal(false)
    }
  })

  const normalBanMutate = useMutation(Requests.normalBan, {
    onSuccess: async (response) => {
      setBanModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setBanModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const deviceBanMutate = useMutation(Requests.deviceBan, {
    onSuccess: async (response) => {
      setBanModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setBanModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const locationBanMutation = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const startChat = () => {
    startChatMutate.mutate({ ticketID: report._id })
  }

  const startChatM = () => {
    setStartChatModal(true)
  }

  const sendCloseReport = () => {
    closeTicketMutate.mutate({ ticketID: report._id })
  }

  const warnUser = () => {
    const title = prompt('Başlık')
    const content = prompt('İçerik')

    warnUserMutate.mutate({
      ticketID: report._id,
      body: {
        title,
        content
      }
    })
  }

  const showChat = () => {
    setDropdownOpen(false)
    showChatMutate.mutate({ ticketID: report._id })
  }

  const banFromShuffle = () => {
    banFromShuffleMutate.mutate({ ticketID: report._id })
  }

  const normalBan = () => {
    normalBanMutate.mutate({
      ticketID: report._id,
      body: {
        banReason,
        banDuration: startDate
      }
    })
  }

  const deviceBan = () => {
    deviceBanMutate.mutate({ ticketID: report._id, banReason })
  }

  const closeReport = () => {
    setVaryingContentone(true)
  }

  const seeBioDetails = (bio) => {
    setBiog(bio)
    setVerticalcenter(true)
  }

  const banUserModal = () => {
    setBanModal(true)
    setDropdownOpen(false)
  }

  const banUser = () => {
    setBanModal(false)
  }

  const banFromShuffleModal = () => {
    setRemoveSuffleModal(true)
    setDropdownOpen(false)
  }

  const warnUserModal = () => {
    setWarnUserModal(true)
    setDropdownOpen(false)
  }

  const locationBan = () => {
    setDropdownOpen(false)
    locationBanMutation.mutate({ userId: report.reportedUser._id, action: 'locationBan' })
    setDropdownOpen(false)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '500px' }}>
            <CardBody>
              <div className="ribbon ribbon-right">
                <Button onClick={closeReport} size="sm">
                  <i className="icofont icofont-ui-close" />
                  {'  Kapat'}
                </Button>
              </div>
              <div className="ribbon ribbon-left">
                <div>
                  <h6 style={{ color: '#5e43be' }}>{'Tarih : '}</h6>
                  <b
                    className="position-absolute"
                    style={{
                      color: 'black',
                      left: '75px',
                      top: '-4px',
                      width: '150px'
                    }}
                  >
                    {moment(report.date).utc(true).local(true).format('DD.MM.YYYY - HH:mm', true)}
                  </b>
                </div>
              </div>

              <div className="row">
                <div
                  className="col-sm-6 span 4 border rounded col-xs- container text-center h-40 d-inline-block p-4"
                  style={{
                    borderColor: 'gray',
                    borderWidth: '10px'
                  }}
                >
                  <img
                    src={report?.creator?.extra?.profilePic || pp}
                    alt=""
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50px'
                    }}
                  />
                  <div>
                    <b style={{ color: 'gray' }}>Kullanıcı Adı</b>
                    <h6 style={{ marginTop: '5x' }}>
                      {report?.creator?.extra?.name || 'undefined'}
                    </h6>
                    <b style={{ color: 'gray' }}>Biyografi</b>
                    <div>
                      <p
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          lineHeight: '1.5em',
                          height: '3em',
                          fontSize: '12px',
                          maxLines: 2,
                          color: 'black'
                        }}
                      >
                        {report?.creator?.profile?.bio || ''}
                      </p>
                      {report?.creator?.profile?.bio?.length > 46 && (
                        <b
                          style={{ color: '#5e43be', cursor: 'pointer' }}
                          onClick={() => seeBioDetails(report?.creator?.profile?.bio)}
                        >
                          Devamını Gör
                        </b>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="col-sm-6  span 4 border rounded col-xs- container text-center h-40 d-inline-block p-4 "
                  style={{
                    borderColor: 'gray',
                    borderWidth: '10px'
                  }}
                >
                  <img
                    src={report?.reportedUser?.extra?.profilePic || pp}
                    alt=""
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50px'
                    }}
                  />
                  <div>
                    <b style={{ color: 'gray' }}>Kullanıcı Adı</b>
                    <h6 style={{ marginTop: '5px' }}>
                      {report?.reportedUser?.extra?.name || 'Karma'}
                    </h6>
                    <b style={{ color: 'gray' }}>Biyografi</b>
                    <p
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        lineHeight: '1.5em',
                        height: '3em',
                        fontSize: '12px',
                        maxLines: 2,
                        color: 'black'
                      }}
                    >
                      {report?.reportedUser?.profile?.bio || ''}
                    </p>

                    <div>
                      {report?.reportedUser?.profile?.bio?.length > 46 && (
                        <b
                          style={{
                            color: '#5e43be',
                            cursor: 'pointer'
                          }}
                          onClick={() => seeBioDetails(report?.reportedUser?.profile?.bio)}
                        >
                          Devamını Gör
                        </b>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-md-4 border rounded mt-2 mb-3 w-100 h-50 d-inline-block">
                  <b style={{ color: 'gray' }}>Ticket Detay</b>
                  <p style={{ color: 'black' }}>{report.message}</p>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center ">
                <div>
                  <Button className="btn-air-primary" color="success" onClick={startChatM}>
                    Chat Başlat
                  </Button>
                  <div className="btn-group dropup">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={toggle}
                    >
                      işlemler
                    </button>
                    <div
                      className={`dropdown-menu${
                        dropdownOpen ? '-show' : ''
                      } position-absolute border `}
                      style={{
                        bottom: '35px',
                        backgroundColor: 'white',
                        borderColor: 'gray',
                        borderWidth: '10px'
                      }}
                    >
                      <p
                        style={{
                          marginBottom: '3px',
                          marginTop: '0px',
                          cursor: 'pointer',
                          fontSize: '15px'
                        }}
                        className="dropdown-item"
                        onClick={banUserModal}
                      >
                        Kullanıcıyı Uzaklaştır
                      </p>
                      <p
                        style={{
                          marginBottom: '3px',
                          marginTop: '0px',
                          cursor: 'pointer',
                          fontSize: '15px'
                        }}
                        className="dropdown-item"
                        onClick={banFromShuffleModal}
                      >
                        Shuffle'dan Kaldır
                      </p>
                      <p
                        style={{
                          marginBottom: '3px',
                          marginTop: '0px',
                          cursor: 'pointer',
                          fontSize: '15px'
                        }}
                        onClick={warnUserModal}
                        className="dropdown-item"
                      >
                        Rapor Edileni Uyar
                      </p>
                      <p
                        style={{
                          marginBottom: '3px',
                          marginTop: '0px',
                          cursor: 'pointer',
                          fontSize: '15px'
                        }}
                        className="dropdown-item"
                        onClick={locationBan}
                      >
                        Location Ban
                      </p>
                      <p
                        style={{
                          marginBottom: '0px',
                          marginTop: '0px',
                          cursor: 'pointer',
                          fontSize: '15px'
                        }}
                        onClick={showChat}
                        className="dropdown-item"
                      >
                        Chat'i Görüntüle
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>Rapor Kapatma</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">
                        Mesaj
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="message-text"
                        onChange={(e) => setCloseInfo(e.target.value)}
                      />
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={VaryingContentonetoggle}>
                    Vazgeç
                  </Button>
                  <Button color="primary" onClick={sendCloseReport}>
                    Onayla
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle}>
                <ModalHeader toggle={Verticalcentermodaltoggle}>Biyografi Detayı</ModalHeader>
                <ModalBody>
                  <h5>{biog}</h5>
                </ModalBody>
              </Modal>
              <ModalWithInputDate
                isOpen={banModal}
                setIsOpen={setBanModal}
                title="Kullanıcıyı Uzaklaştır"
                applyHandler={banUser}
                startDate={startDate}
                setstartDate={setstartDate}
                setInput={setBanReason}
                ban={normalBan}
                deviceBan={deviceBan}
              />
              <ModalNoInput
                isOpen={removeShuffleModal}
                setIsOpen={setRemoveSuffleModal}
                title="Shuffleden Kaldır"
                desc={"Kullanıcıyı shuffle'den kaldırmak istiyor musunuz?"}
                applyHandler={banFromShuffle}
              />
              <ModalNoInput
                isOpen={warnModal}
                setIsOpen={setWarnUserModal}
                title="Kullanıcıyı Uyar"
                desc="Kullanıcıyı Uyarmak istiyor musunuz?"
                applyHandler={warnUser}
              />
              {ModalForChat}
              <ModalForChat
                isOpen={showChatModal}
                setIsOpen={setShowChatModal}
                title="Kullanıcılar arası sohbet geçmişi"
                desc={getChatMessages}
                applyHandler={showChat}
              />
              <ModalNoInput
                isOpen={startChatModal}
                setIsOpen={setStartChatModal}
                title="Kullanıcı ile sohbet başlat ?"
                desc="Kullanıcıyla sohbet başlatır"
                applyHandler={startChat}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsForOpenReport
