import { useCallback, useEffect, useRef, useState } from 'react'

export const useElementIsVisible = () => {
  const observer = useRef()
  const [isVisible, setIsVisible] = useState(false)

  const handleObserver = useCallback((entries) => {
    const [entry] = entries

    if (entry.isIntersecting) setIsVisible(true)
  }, [])

  const ref = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(handleObserver)
      if (node) observer.current.observe(node)
    },
    [handleObserver]
  )

  return [ref, isVisible]
}
