import React, { Fragment, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'

import RibbonsCustom from '../components/custom/RibbonsCustom'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

const AppLayout = ({ children, classNames, ...rest }) => {
  const [venusProfiles, setVenusProfiles] = useState([])

  const mutation = useMutation(Requests.getVenus, {
    onSuccess: async (response) => {
      setVenusProfiles(response)
    }
  })

  useEffect(async () => {
    mutation.mutate()
  }, [])

  return (
    <>
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Aboneler" />
              <div className="row row-cols-auto">
                {venusProfiles.map((e) => (
                  <RibbonsCustom
                    image={e.user.extra.thumbnail || e.user.extra.profilePic}
                    name={e.user.name}
                    meteorite={e.meteorite}
                    type={e.gift ? 'Gifted' : 'Paid'}
                    userID={e.user._id}
                    phone={e.user?.phoneNumber}
                    venusProfiles={venusProfiles}
                    setVenusProfiles={setVenusProfiles}
                  />
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AppLayout
