import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FormSelect } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { Container, Button, FormGroup, Label, Input } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

const CreateNotifyForm = () => {
  const [header, setHeader] = useState('')
  const [content, setContent] = useState('')
  const [sign, setSign] = useState(0)
  const [planet, setPlanet] = useState(0)
  const [startDate, setstartDate] = useState(new Date())
  const [lockDate, setLockDate] = useState(false)
  const [lang, setLang] = useState('tr-TR')
  const [time, setTime] = useState('daily')
  const lockDateCheckBox = () => {
    setLockDate(!lockDate)
  }

  const createNotificationMutate = useMutation(Requests.createNotification, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Bildirim Kaydedildi', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const createNotification = () => {
    const dateValue = new Date(startDate)
    const timezoneOffset = dateValue.getTimezoneOffset() * 60000

    SweetAlert.fire({
      title: `Kaydet?`,
      text: `${header} başlıklı bildirimin kaydedilmesini onaylıyor musun?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        createNotificationMutate.mutate({
          body: {
            contentType: 'Daily',
            zodiacSign: sign,
            planet,
            publishDate: new Date(dateValue.getTime() - timezoneOffset),
            title: header || 'test',
            subtitle: header || 'Merhaba',
            notificationTitle: header || 'Merhaba',
            content,
            languageCode: lang,
            ...(lockDate && { highPriority: true })
          }
        })
      }
    })
  }

  const languageHandler = (e) => {
    setLang(e.target.value)
  }

  const timeHandler = (e) => {
    setTime(e.target.value)
  }

  return (
    <Container fluid>
      <FormGroup>
        <div>
          <div className="row">
            <div className="col col-sm-col-md-1">
              <Label style={{ marginLeft: '10px' }}>Hitap</Label>
              <Input
                className="form-control btn-pill"
                id="header"
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
            <div className="col col-sm-col-md-3">
              <label style={{ marginLeft: '10px' }}>Tarih</label>
              <div className="input-group">
                <ReactDatePicker
                  className="form-control digits btn-pill"
                  selected={startDate}
                  preventOpenOnFocus
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setstartDate(date)}
                />
                <div
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    alignItems: 'center'
                  }}
                >
                  <Input
                    className="checkbox_animated"
                    id="chk-ani"
                    type="checkbox"
                    onChange={lockDateCheckBox}
                  />
                  <Label style={{ marginLeft: '5px' }}>Tarihi Kilitle</Label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-">
            <div>
              <Label>İçerik</Label>
              <Input
                type="textarea"
                className="form-control btn-pill-less"
                rows="3"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: '40px' }}>
            <div className="col col-xs">
              <Label>İçerik Türü</Label>
              <div className="col-">
                <Label for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="daily"
                    type="radio"
                    name="rdo-ani"
                    value="daily"
                    onChange={(e) => timeHandler(e)}
                    defaultChecked
                  />
                  {Option} Daily
                </Label>
                <div>
                  <Label for="edo-ani1">
                    <Input
                      className="radio_animated"
                      id="weekly"
                      type="radio"
                      name="rdo-ani"
                      value="weekly"
                      onChange={(e) => timeHandler(e)}
                    />
                    {Option} Weekly
                  </Label>
                </div>
                <Label for="edo-ani2">
                  <Input
                    className="radio_animated"
                    id="monthly"
                    type="radio"
                    name="rdo-ani"
                    value="monthly"
                    onChange={(e) => timeHandler(e)}
                  />
                  {Option} Monthly
                </Label>
              </div>
            </div>
            <div className="col col-xs">
              <Label>Dil</Label>
              <div>
                <Label for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="ig-language"
                    type="radio"
                    name="languageRadioOptions"
                    value="tr-TR"
                    onChange={(e) => languageHandler(e)}
                    defaultChecked
                  />
                  {Option} Türkçe
                </Label>
                <div>
                  <Label for="edo-ani1">
                    <Input
                      className="radio_animated"
                      id="ig-language"
                      type="radio"
                      name="languageRadioOptions"
                      value="en-EN"
                      onChange={(e) => languageHandler(e)}
                    />
                    {Option} English
                  </Label>
                </div>
                <Label for="edo-ani2">
                  <Input
                    className="radio_animated"
                    id="ig-language"
                    type="radio"
                    name="languageRadioOptions"
                    value="es-ES"
                    onChange={(e) => languageHandler(e)}
                  />
                  {Option} Spanish
                </Label>
              </div>
            </div>
            <div className="col col-xs">
              <Label>Burç</Label>
              <FormSelect
                aria-label="Default select example"
                onChange={(opt) => setSign(opt.target.value)}
              >
                <option value="Ari">Aries</option>
                <option value="Tau">Taurus</option>
                <option value="Gem">Gemini</option>
                <option value="Can">Cancer</option>
                <option value="Leo">Leo</option>
                <option value="Vir">Virgo</option>
                <option value="Lib">Libra</option>
                <option value="Sco">Scorpio</option>
                <option value="Sag">Sagittarius</option>
                <option value="Cap">Capricorn</option>
                <option value="Aqu">Aquarius</option>
                <option value="Pis">Pisces</option>
              </FormSelect>
            </div>
            <div className="col col-xs">
              <Label>Gezegen</Label>
              <FormSelect
                aria-label="Default select example"
                onChange={(opt) => setPlanet(opt.target.value)}
              >
                <option value="ascendant">Ascendant</option>
                <option value="sun">Sun</option>
                <option value="moon">Moon</option>
                <option value="mercury">Mercury</option>
                <option value="mars">Mars</option>
                <option value="venus">Venus</option>
                <option value="jupiter">Jupiter</option>
                <option value="saturn">Saturn</option>
                <option value="uranus">Uranus</option>
                <option value="neptune">Neptune</option>
                <option value="pluto">Pluto</option>
              </FormSelect>
            </div>
          </div>
          <div
            className="d-grid gap-2"
            style={{
              marginTop: '40px',
              maxWidth: '100%'
            }}
          >
            <Button color="primary" style={{ alignSelf: 'center' }} onClick={createNotification}>
              Bildirimi Kaydet
            </Button>
          </div>
        </div>
      </FormGroup>
    </Container>
  )
}

export default CreateNotifyForm
