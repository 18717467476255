import React, { useState, useEffect, createRef, useRef } from 'react'

import { useMutation } from '@tanstack/react-query'
import BSON from 'bson'
import { Picker } from 'emoji-mart'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Form,
  Input,
  InputGroup,
  Button
} from 'reactstrap'
import io from 'socket.io-client'

import start_conversion from '../../assets/images/start-conversion.jpg'
import { watchfetchChatMember, watchfetchChat } from '../../redux/chap-app/action'
import * as Requests from '../../utils/requests'
import ModalNoInput from './ModalNoInput'

import karmapp from '../../assets/images/dummypp.png'
import emptypp from '../../assets/images/emptypp.png'
import errorImg from '../../assets/images/search-not-found.png'

const ChatComponent = () => {
  const dispatch = useDispatch()
  const currentUser = useSelector((content) => content.ChatApp.currentUser)

  const [searchKeyword, setSearchKeyword] = useState('')
  const [messageInput, setMessageInput] = useState('')
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [allChats, setAllChats] = useState([])
  const [chatList, setChatList] = useState([])
  const [currentChat, setCurrentChat] = useState([])
  const [CurrentMessages, setCurrentMessages] = useState([])
  const [socketInstance, setSocket] = useState()
  const [onlineUsers, setOnlineUsers] = useState([])
  const [isTyping, setIsTyping] = useState(false)
  const [isUserOnline, setIsUserOnline] = useState(false)
  const [userInfoModal, setUserInfoModal] = useState(false)
  const [userInfoDesc, setUserInfoDesc] = useState('')
  const [typeAnim, setTypeAnim] = useState(false)
  const [chatNextPage, setChatNextPage] = useState(0)
  const chatsScreen = createRef()

  const scrollContainerRef = useRef(null)

  const getChatsMutation = useMutation(Requests.getChats, {
    onSuccess: async (response) => {
      setAllChats((prev) => [...prev, ...response])
      setChatList((prev) => [...prev, ...response])
    },
    onError: (e) => {
      console.log(`Hata Meydana geldi ${e}`)
    }
  })

  const getCurrentChatMutation = useMutation(Requests.getCurrentChat, {
    onSuccess: async (response) => {
      setCurrentMessages(response.messages)
    },
    onError: (e) => {
      console.log(`Hata Meydana geldi ${e}`)
    }
  })

  const showUserInfo = useMutation(Requests.showUserInfo, {
    onSuccess: async (response) => {
      setUserInfoDesc(response)
      setUserInfoModal(true)
    },
    onError: (e) => {
      console.log(`Hata Meydana geldi ${e}`)
    }
  })

  useEffect(() => {
    dispatch(watchfetchChatMember())
    dispatch(watchfetchChat())
  }, [dispatch])

  useEffect(() => {
    // Make sure the ref is current before setting up the event listener
    const scrollContainer = scrollContainerRef.current

    if (!scrollContainer) return

    const handleScroll = () => {
      const isAtBottom =
        scrollContainer.scrollHeight - scrollContainer.scrollTop <= scrollContainer.clientHeight + 1

      if (isAtBottom) {
        fetchNewMessages()
      }
    }

    scrollContainer.addEventListener('scroll', handleScroll)

    // Cleanup function
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [scrollContainerRef.current])

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET, {
      query: {
        token: localStorage.getItem('accessToken')
      },
      transports: ['websocket']
    })

    setSocket(socket)

    getChatsMutation.mutate({ nextPage: chatNextPage })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (socketInstance) {
      socketInstance.on('typing', (typer, chatId) => {
        let cChat = []

        setCurrentChat((prev) => {
          cChat = prev

          return prev
        })

        if (cChat.length === 0) return

        if (
          typer !== '612fe850198dac2f1b608864' &&
          (typer === cChat.user1._id || typer === cChat.user2._id)
        ) {
          setTypeAnim(true)
        }
      })

      socketInstance.on('stoped_typing', (typer, chatId) => {
        let cChat = []

        setCurrentChat((prev) => {
          cChat = prev

          return prev
        })

        if (cChat.length === 0) return

        if (
          typer !== '612fe850198dac2f1b608864' &&
          (typer === cChat.user1._id || typer === cChat.user2._id)
        ) {
          setTypeAnim(false)
        }
      })

      if (allChats)
        socketInstance.on('message', ({ chatId, message }) => {
          const chats = []
          const newChat = []
          let curChat = 0

          setChatList((prev) => {
            chats.push(prev)

            return prev
          })

          const chat = chats[0].find((chat) => chat._id === chatId)

          chat.messages.unshift(message)

          setChatList((prev) => {
            newChat.push(prev.filter((chat) => chat._id !== chatId))

            return prev.filter((chat) => chat._id !== chatId)
          })

          newChat[0].unshift(chat)
          setChatList(newChat[0])

          setCurrentChat((prev) => {
            curChat = prev

            return prev
          })

          if (chatId === curChat._id) {
            let getchat = []

            setCurrentMessages((prev) => {
              getchat = prev

              return prev
            })

            getchat.push(message)
            setCurrentMessages(getchat)
            let input = ''

            setMessageInput((prev) => {
              input = prev

              return prev
            })
            setMessageInput('!')
            setMessageInput(input)
          }
        })

      socketInstance.on('online_back', (chatId) => {
        const chat = onlineUsers.find((cht) => cht === chatId)

        if (!chat) {
          setOnlineUsers((prev) => ({ ...prev, chat }))
        }
      })
    }
  }, [socketInstance])

  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }

  const addEmoji = (emoji) => {
    const text = `${messageInput}${emoji.native}`

    setShowEmojiPicker(false)
    setMessageInput(text)
  }

  const handleSearchKeyword = (keyword) => {
    setSearchKeyword(keyword)
    // setChatList(chatList.find((chat) => chat.user2.name === keyword));
  }

  const handleMessageChange = (message) => {
    if (message.length > 0 && !isTyping) {
      setIsTyping(true)
      socketInstance.emit('typing', currentChat._id)
    } else if (message.length <= 0 && isTyping) {
      setIsTyping(false)
      socketInstance.emit('stoped_typing', currentChat._id)
    }

    setMessageInput(message)
  }

  const handleMessagePress = (e) => {
    if (e.key === 'Enter' || e === 'send') {
      if (messageInput.length > 0) {
        const newMessage = {
          _id: new BSON.ObjectId().toString(),
          text: messageInput,
          song: undefined
        }

        socketInstance.emit('message', currentChat._id, newMessage)

        // Adding a message to the state here if you manage messages in a local state
        // updateMessages(prev => [...prev, newMessage]);

        setMessageInput('')

        setTimeout(() => {
          const container = document.querySelector('.chat-history')

          if (container) {
            container.scrollTop = container.scrollHeight
          }
        }, 0)
      }
    }
  }

  const getChat = (chatID) => {
    const container = document.querySelector('.chat-history')

    let currentChatId = null

    setTimeout(() => {
      container.scrollBy({ top: 200, behavior: 'smooth' })
    }, 100)

    setCurrentChat(() => {
      currentChatId = chatList.find((cht) => cht._id === chatID)

      if (currentChatId) {
        socketInstance.emit('received_all', currentChatId._id)

        setChatList((chats) =>
          chats.map((chat) => {
            if (chat._id === currentChatId._id) {
              return {
                ...chat,
                messages: chat.messages.map((message) => ({
                  ...message,
                  received: true
                }))
              }
            }

            return chat
          })
        )
      }

      return currentChatId
    })

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    getCurrentChatMutation.mutate({ chatID })

    if (onlineUsers?.find((online) => online === chatID)) setIsUserOnline(true)
    else setIsUserOnline(false)
  }

  const fetchNewMessages = () => {
    setChatNextPage((prev) => {
      getChatsMutation.mutate({ nextPage: prev + 1 })

      return prev + 1
    })
  }

  // TODO: Add this.
  const showUserTickets = () => {
    // axios
    //   .get(
    //     `${process.env.REACT_APP_API_TEST}/v1/ticket/user-tickets/${
    //       currentChat.user2.mail || currentChat.user2.phoneNumber
    //     }`
    //   )
    //   .then(({ data }) => {
    //     data.map((t) => {
    //       if (t.entityType === "TICKET")
    //         return t.status === "Open" ? (
    //           <RibbonsForOpenTicket
    //             image={t.creator?.extra.thumbnail || karmapp}
    //             key={t._id}
    //             name={t.creator?.name}
    //             date={t.date}
    //             message={t.message}
    //             ticketID={t._id}
    //             allInform={t}
    //           />
    //         ) : (
    //           <RibbonsForClosedTicket
    //             image={t.creator?.extra.thumbnail || karmapp}
    //             name={t.creator?.name}
    //             message={t.message}
    //             date={t.date}
    //             allInform={t}
    //             ticketID={t._id}
    //           />
    //         );
    //       else if (t.entityType === "REPORT")
    //         return t.status === "Open" ? (
    //           <RibbonsForOpenReport report={t} />
    //         ) : (
    //           <RibbonsForClosedReport report={t} />
    //         )
    //         else;
    //     });
    //   });
  }

  const showUserInform = () => {
    showUserInfo.mutate({ userID: currentChat.user2._id })
  }

  return chatList ? (
    <Container fluid>
      <Row>
        <Col sm="12" className="call-chat-sidebar">
          <Card>
            <CardBody className="chat-body">
              <div className="chat-box">
                <div className="chat-left-aside">
                  <div className="media">
                    <Media src={karmapp} className="rounded-circle user-image" alt="" />
                    <div className="about">
                      <h6>
                        <div className="name f-w-600">Karma Destek</div>
                      </h6>
                      <div className="status" style={{ color: 'green' }}>
                        Aktif
                      </div>
                    </div>
                  </div>
                  <div className="people-list">
                    <div className="search">
                      <Form className="theme-form">
                        <div className="mb-3">
                          <Input
                            className="form-control"
                            type="text"
                            placeholder="search"
                            defaultValue={searchKeyword}
                            onChange={(e) => handleSearchKeyword(e.target.value)}
                          />
                          <i className="fa fa-search" />
                        </div>
                      </Form>
                    </div>
                    {chatList.length > 0 ? (
                      <ul className="chat-list-container" ref={scrollContainerRef}>
                        {chatList
                          .filter((x) => x.id !== currentUser.id)
                          .map((chat, i) => {
                            let notSeen = false

                            if (!chat.messages[0]?.sender) {
                              return null
                            }

                            if (
                              chat.messages[0]?.sender !== '612fe850198dac2f1b608864' &&
                              chat.messages[0]?.received === false
                            ) {
                              notSeen = true
                            }

                            const lastMessage =
                              chat.messages.length >= 1 ? chat.messages[0] : 'Konuşma başlattın'

                            return (
                              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                              <li
                                className="clearfix clearfix-chat-list p-2"
                                key={chat._id}
                                onClick={() => getChat(chat._id)}
                              >
                                <img
                                  src={`${chat.user2?.extra?.thumbnail || emptypp}`}
                                  className="rounded-circle user-image"
                                  alt=""
                                />
                                <div className="about">
                                  <div
                                    className="name"
                                    style={
                                      notSeen ? { color: '#3e06b1', fontWeight: 'bolder' } : {}
                                    }
                                  >
                                    {chat.user2?.name}
                                  </div>
                                  <div
                                    className="status"
                                    style={
                                      notSeen ? { color: '#3e06b1', fontWeight: 'bolder' } : {}
                                    }
                                  >
                                    {lastMessage?.text?.slice(0, 21)}
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                      </ul>
                    ) : (
                      <Media className="img-fluid m-auto" src={errorImg} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        {JSON.stringify(currentChat).length > 2 ? (
          <Col className="call-chat-body">
            <Card>
              <CardBody className="p-0">
                <Row className="chat-box">
                  <Col className="pe-0 chat-right-aside">
                    <div className="chat">
                      <div className="chat-header clearfix">
                        <Media
                          src={currentChat.user2?.extra?.thumbnail || emptypp}
                          className="rounded-circle"
                          alt=""
                        />
                        <div className="about">
                          <div className="name">{currentChat.user2?.name}</div>
                          <div className="status digits">{isUserOnline ? 'Online' : 'Offline'}</div>
                        </div>
                        <ul className="list-inline float-start float-sm-end chat-menu-icons">
                          <li className="list-inline-item">
                            <Button
                              class="btn-air-primary"
                              size="sm"
                              color="primary"
                              onClick={showUserInform}
                            >
                              Kullanıcı Bilgileri
                            </Button>
                          </li>
                          <li className="list-inline-item">
                            <Button
                              class="btn-air-primary"
                              size="sm"
                              color="primary"
                              onClick={showUserTickets}
                            >
                              Kullanıcı Ticketleri
                            </Button>
                          </li>
                        </ul>
                      </div>
                      <div className="chat-history chat-msg-box custom-scrollbar" id="chatbox">
                        <ul ref={chatsScreen}>
                          {CurrentMessages.length > 0 ? (
                            CurrentMessages.map((message, index) => {
                              const otherUser = message.sender !== currentChat.user1?._id

                              return (
                                <li key={index} className="clearfix justify-content-end">
                                  <div>
                                    <Media
                                      src={
                                        otherUser
                                          ? (currentChat.user2?.extra || emptypp)?.thumbnail
                                          : karmapp
                                      }
                                      className={`rounded-circle ${
                                        otherUser ? 'float-start' : 'float-end'
                                      } chat-user-img img-50`}
                                      alt=""
                                    />
                                    <div
                                      className={`message ${
                                        otherUser
                                          ? 'other-message float-start other-message-text'
                                          : 'my-message float-end my-message-text'
                                      }`}
                                    >
                                      {message.text}
                                    </div>
                                    <div className={`message-data text-end `}>
                                      <span
                                        className={`message-data-time ${
                                          otherUser ? 'float-start' : 'float-end'
                                        }`}
                                      >
                                        {moment(message.createdAt)
                                          .utc(true)
                                          .local(true)
                                          .format('DD.MM.YYYY - HH:mm', true)}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              )
                            })
                          ) : (
                            <div>
                              <Media
                                className="img-fluid"
                                src={start_conversion}
                                alt="start conversion "
                              />
                            </div>
                          )}
                        </ul>
                      </div>
                      <div className="chat-message clearfix">
                        <Row>
                          <div className="mb-2">
                            {showEmojiPicker ? (
                              <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
                            ) : null}
                          </div>
                          {typeAnim && <h6>Yazıyor...</h6>}
                          <Col xl="12" className="d-flex">
                            <div className="smiley-box bg-primary">
                              <div className="picker" onClick={() => toggleEmojiPicker()}>
                                <Media src={require('../../assets/images/smiley.png')} alt="" />
                              </div>
                            </div>
                            <InputGroup className="text-box">
                              <Input
                                type="text"
                                className="form-control input-txt-bx"
                                placeholder="Type a message......"
                                value={messageInput}
                                onKeyPress={(e) => handleMessagePress(e)}
                                onChange={(e) => handleMessageChange(e.target.value)}
                              />
                              <Button
                                className="input-group-text"
                                color="primary"
                                onClick={() => handleMessagePress('send')}
                              >
                                Gönder
                              </Button>
                            </InputGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>
      {userInfoModal && (
        <ModalNoInput
          title="Kullanıcı Bilgileri"
          userInformModal
          data={userInfoDesc}
          isOpen={userInfoModal}
          setIsOpen={setUserInfoModal}
          applyHandler={() => setUserInfoModal(false)}
        />
      )}
    </Container>
  ) : (
    <div className="loading" />
  )
}

export default ChatComponent
