import React, { useState, Fragment, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import { Pagination } from 'react-bootstrap'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'

import * as Requests from '../../utils/requests'
import PaginationCustom from './PaginationCustom'
import { RibbonsCustomForVerification } from './RibbonsCustomForVerification'

const TabLineCustomForVerifReq = () => {
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1')
  const [openVerifies, setOpenVerifies] = useState([])
  const [closedVerifies, setClosedVerifies] = useState([])
  const [awsDenied, setAwsDenied] = useState([])
  const [paginationActive, setPaginationActive] = useState(1)

  const getTicketsMutation = useMutation(Requests.getVerificationRequests, {
    onSuccess: (response) => {
      setOpenVerifies(response)
    }
  })

  const getClosedTicketsMutation = useMutation(Requests.getVerificationRequests, {
    onSuccess: (response) => {
      setClosedVerifies(response)
    }
  })

  const getAwsDeniedMutation = useMutation(Requests.getVerificationRequests, {
    onSuccess: (response) => {
      setAwsDenied(response)
    }
  })

  useEffect(() => {
    getTicketsMutation.mutate({ page: 0, limit: 20, type: 0 })
    getClosedTicketsMutation.mutate({ page: 0, limit: 20, type: 1 })
    getAwsDeniedMutation.mutate({ page: 0, limit: 20, type: 2 })
    setPaginationActive(1)
  }, [])

  const getNewPage = (index, type) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

    setPaginationActive(index)
    if (type === 0) getTicketsMutation.mutate({ type: 0, limit: 20, page: index - 1 })
    else if (type === 1) getClosedTicketsMutation.mutate({ type: 1, limit: 20, page: index - 1 })
    else if (type === 2) getAwsDeniedMutation.mutate({ type: 2, limit: 20, page: index - 1 })
  }

  const createPage = (page, type) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationActive}
          pageNumb={index}
          getNewPage={() => getNewPage(index, type)}
        />
      )
    }

    return <div className="d-flex justify-content-center">{items}</div>
  }

  return (
    <Container fluid style={{ width: '95%' }}>
      <Row>
        <Col>
          <Card style={{ width: '90%' }}>
            <CardBody>
              <Nav className="border-tab nav-primary" tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '1' ? 'active' : ''}
                    onClick={() => {
                      getTicketsMutation.mutate({ page: 0, limit: 20, type: 0 })
                      setPaginationActive(1)
                      setPrimarycolorLineTab('1')
                    }}
                  >
                    <i className="icofont icofont-document-search" />
                    Aktif Doğrulama İstekleri
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '2' ? 'active' : ''}
                    onClick={() => {
                      getAwsDeniedMutation.mutate({ page: 0, limit: 20, type: 2 })
                      setPrimarycolorLineTab('2')
                      setPaginationActive(1)
                    }}
                  >
                    <i className="icofont icofont-tick-boxed" />
                    AWS Reddedilenler
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '3' ? 'active' : ''}
                    onClick={() => {
                      getClosedTicketsMutation.mutate({ page: 0, limit: 20, type: 1 })
                      setPrimarycolorLineTab('3')
                      setPaginationActive(1)
                    }}
                  >
                    <i className="icofont icofont-tick-boxed" />
                    Kapalı Doğrulama İstekleri
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={PrimarycolorLineTab}>
                <TabPane className="fade show" tabId="1">
                  <div className="row row-cols-auto">
                    {openVerifies.map((v) => (
                      <RibbonsCustomForVerification
                        id={v._id}
                        userId={v.user}
                        profilePic={v.profilePhoto}
                        imageUrl={v.imageUrl}
                        baseImage={v.baseImage}
                        openVerifies={openVerifies}
                        createdAt={v.createdAt}
                        setOpenVerifies={setOpenVerifies}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">{createPage(20, 0)}</ul>
                    </Pagination>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row row-cols-auto">
                    {awsDenied.map((v) => (
                      <RibbonsCustomForVerification
                        id={v._id}
                        userId={v.user}
                        profilePic={v.profilePhoto}
                        imageUrl={v.imageUrl}
                        baseImage={v.baseImage}
                        createdAt={v.createdAt}
                        openVerifies={awsDenied}
                        setOpenVerifies={setAwsDenied}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">{createPage(20, 2)}</ul>
                    </Pagination>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="row row-cols-auto">
                    {closedVerifies.map((v) => (
                      <RibbonsCustomForVerification
                        id={v._id}
                        userId={v.user}
                        profilePic={v.profilePhoto}
                        imageUrl={v.imageUrl}
                        baseImage={v.baseImage}
                        createdAt={v.createdAt}
                        updatedAt={v.updatedAt}
                        openVerifies={openVerifies}
                        setOpenVerifies={setOpenVerifies}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">{createPage(20, 1)}</ul>
                    </Pagination>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TabLineCustomForVerifReq
