import React, { Fragment } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

const RibbonsCustomForCharmful = ({
  image,
  name,
  lastActivity,
  type = 'Charmful',
  userID,
  charmfulUsers,
  setCharmfulUsers
}) => {
  const removeTagMutate = useMutation(Requests.removeCharmful, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Etiket kaldırıldı.', 'success')
      setCharmfulUsers(charmfulUsers.filter((usr) => usr._id !== userID))
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const removeTag = () => {
    SweetAlert.fire({
      title: `${name} adlı kullanıcının etiketini kaldır?`,
      text: 'Kullanıcıya ait charmful etiketini sonlandırır.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        removeTagMutate.mutate({
          userID,
          body: {
            tag: 'Charmful'
          }
        })
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '350px' }}>
            <CardBody>
              <div
                className="ribbon ribbon-info ribbon-right"
                style={{ borderRadius: '10px', marginRight: '10px' }}
              >
                {type}
              </div>
              <img
                src={image}
                alt=""
                style={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '10px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              <p>{lastActivity}</p>
              <Button className="btn-air-primary" color="primary" onClick={removeTag}>
                Etiketi Kaldır
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsCustomForCharmful
