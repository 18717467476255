import React, { useState, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Pagination
} from 'reactstrap'

import * as Requests from '../../utils/requests'
import PaginationCustom from './PaginationCustom'
import RibbonsForClosedTicket from './RibbonsCustomForClosedTicket'
import RibbonsForOpenTicket from './RibbonsCustomForOpenTicket'

import noPP from '../../assets/images/dummypp.png'

const TabLineCustomForTickets = () => {
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1')
  const [tickets, setTickets] = useState([])
  const [closedTickets, setClosedTickets] = useState([])
  const [pageCount, setPageCount] = useState(1)
  const [pageCountClosed, setPageCountClosed] = useState(1)

  const [paginationActive, setPaginationActive] = useState({
    next: {
      page: 1,
      limit: 21
    }
  })
  const [paginationClosed, setPaginationClosed] = useState({
    next: {
      page: 1,
      limit: 21
    }
  })

  const getClosedTickets = useMutation(Requests.getClosedTickets, {
    onSuccess: async (response) => {
      setClosedTickets(response.tickets)
      setPaginationClosed(response.pagination)
      setPageCountClosed(response.totalCount / response.pagination.next.limit)
    }
  })

  const getNewClosedTickets = useMutation(Requests.getNewClosedTickets, {
    onSuccess: async (response) => {
      setClosedTickets(response.tickets)
      setPaginationClosed(response.pagination)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  const getNewOpenTickets = useMutation(Requests.getNewOpenTickets, {
    onSuccess: async (response) => {
      setTickets(response.tickets)
      setPaginationActive(response.pagination)
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  })

  const getOpenTickets = useMutation(Requests.getOpenTickets, {
    onSuccess: async (response) => {
      setTickets(response.tickets)
      setPaginationActive(response.pagination)
      setPageCount(response.totalCount / response.pagination.next.limit)
    }
  })

  useEffect(() => {
    getOpenTickets.mutate({
      nextPage: paginationActive.next.page,
      nextLimit: paginationActive.next.limit
    })

    getClosedTickets.mutate({
      nextPage: paginationClosed.next.page,
      nextLimit: paginationClosed.next.limit
    })
  }, [])

  const getNewPage = (index) => {
    getNewOpenTickets.mutate({ index, nextLimit: paginationActive.next.limit })
  }

  const createPage = (page) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationActive.next.page - 1}
          pageNumb={index}
          getNewPage={() => getNewPage(index)}
        />
      )
    }

    return <div className="d-flex justify-content-center">{items}</div>
  }

  const getNewPageClosed = (index) => {
    getNewClosedTickets.mutate({
      index,
      nextLimit: paginationActive.next.limit
    })
  }

  const createPageClosed = (page) => {
    const items = []

    if (page > 20) page = 20
    for (let index = 1; index <= page; index++) {
      items.push(
        <PaginationCustom
          currentPage={paginationClosed.next.page - 1}
          pageNumb={index}
          getNewPage={() => getNewPageClosed(index)}
        />
      )
    }

    return <div className="d-flex justify-content-center">{items}</div>
  }

  return (
    <Container fluid style={{ width: '95%' }}>
      <Row>
        <Col>
          <Card style={{ width: '90%' }}>
            <CardBody>
              <Nav className="border-tab nav-primary" tabs>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '1' ? 'active' : ''}
                    onClick={() => setPrimarycolorLineTab('1')}
                  >
                    <i className="icofont icofont-page" />
                    Aktif Ticketlar
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    className={PrimarycolorLineTab === '2' ? 'active' : ''}
                    onClick={() => setPrimarycolorLineTab('2')}
                  >
                    <i className="icofont icofont-tick-boxed" />
                    Kapalı Ticketlar
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={PrimarycolorLineTab}>
                <TabPane className="fade show" tabId="1">
                  <div className="row row-cols-auto">
                    {tickets.map((t) => (
                      <RibbonsForOpenTicket
                        key={t._id}
                        image={t.creator?.extra.thumbnail || noPP}
                        name={t.creator?.name || 'undefined'}
                        date={t.date}
                        message={t.message}
                        ticketID={t._id}
                        allInform={t}
                        tickets={tickets}
                        setTickets={setTickets}
                        data={t}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">{createPage(pageCount)}</ul>
                    </Pagination>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="row row-cols-auto">
                    {closedTickets.map((t) => (
                      <RibbonsForClosedTicket
                        key={t._id}
                        image={t.creator?.extra.thumbnail || noPP}
                        name={t.creator?.name || 'undefined'}
                        date={t.date}
                        message={t.message}
                        ticketID={t._id}
                        allInform={t}
                        closedTickets={closedTickets}
                        setClosedTickets={setClosedTickets}
                        data={t}
                      />
                    ))}
                  </div>
                  <div className="d-flex justify-content-center row-cols-auto">
                    <Pagination aria-label="...">
                      <ul className="pagination pagination-primary">
                        {createPageClosed(pageCountClosed)}
                      </ul>
                    </Pagination>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default TabLineCustomForTickets
