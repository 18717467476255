import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ModalWithInput = ({
  isOpen,
  setIsOpen,
  setInput,
  applyHandler,
  title,
}) => {
  const toogle = () => setIsOpen(!isOpen);
  return (
    <Modal isOpen={isOpen} toggle={toogle}>
      <ModalHeader toggle={toogle}>{"Ticket Kapatma"}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="message-text">
              {title}
            </Label>
            <Input
              type="textarea"
              className="form-control"
              id="message-text"
              onChange={(e) => setInput(e.target.value)}
            ></Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toogle}>
          {"Vazgeç"}
        </Button>
        <Button color="primary" onClick={applyHandler}>
          {"Onayla"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWithInput;
