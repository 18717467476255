import React, { Fragment } from 'react'

import { ToastContainer } from 'react-bootstrap'

import KnobChartCustom from '../components/custom/KnobChartCustom'
import UsersCountryAndGender from '../components/custom/UsersCountryAndGender'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'

const SpanishUsers = () => (
  <>
    {/* <Loader /> */}
    <Taptop />
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header />
      <div className="page-body-wrapper">
        <Sidebar />
        <div className="page-body">
          <div>
            <Breadcrumbs title="İspanyolca Kullanıcılar" />
            <div className="row row-cols-auto">
              <UsersCountryAndGender language="es-ES" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ToastContainer />
  </>
)

export default SpanishUsers
