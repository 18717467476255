import React, { Fragment, useEffect, useState } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import moment, { lang } from 'moment'
import { Card, Col, Container, Row } from 'react-bootstrap'
import CountUp from 'react-countup'
import ReactDatePicker from 'react-datepicker'
import { Lock, Trash2, User, UserMinus, UserPlus, Users } from 'react-feather'
import Chart from 'react-google-charts'
import { CardBody, Input } from 'reactstrap'

import * as Requests from '../../utils/requests'

export const ImpressionStatsByGender = () => {
  const [startDate, setstartDate] = useState(moment().subtract(3, 'days').toDate())
  const [femaleStats, setFemaleStats] = useState({})
  const [maleStats, setMaleStats] = useState({})
  const [gte10maleStats, setGte10MaleStats] = useState({})
  const [nonTrGte10maleStats, setNonTrGte10MaleStats] = useState({})
  const [gte20NonTrMaleStats, setGte20NonTrMaleStats] = useState({})
  const [lte10FemaleStats, setLte10FemaleStats] = useState({})
  const [nonTrLte10FemaleStats, setNonTrLte10FemaleStats] = useState({})
  const [lte0NonTrFemaleStats, setLte0NonTrFemaleStats] = useState({})

  const femaleQuery = useQuery({
    queryKey: ['users', 'impression', 'male'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Female',
        startDate,
        impression: 0,
        compareBy: 'lte',
        countryCode: 'all'
      }),
    onSuccess: (res) => setFemaleStats(res),
    enabled: false
  })

  const maleQuery = useQuery({
    queryKey: ['users', 'impression', 'female'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Male',
        startDate,
        impression: 20,
        compareBy: 'gte',
        countryCode: 'all'
      }),
    onSuccess: (res) => setMaleStats(res),
    enabled: false
  })

  const maleQueryGte10 = useQuery({
    queryKey: ['users', 'impression', 'male', 'gte10'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Male',
        startDate,
        impression: 10,
        compareBy: 'gte',
        countryCode: 'all'
      }),
    onSuccess: (res) => setGte10MaleStats(res),
    enabled: false
  })

  const nonTrMaleQueryGte10 = useQuery({
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Male',
        startDate,
        impression: 10,
        compareBy: 'gte',
        countryCode: 'TR'
      }),
    onSuccess: (res) => setNonTrGte10MaleStats(res),
    enabled: false
  })

  const femaleQueryLte10 = useQuery({
    queryKey: ['users', 'impression', 'female', 'lte10'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Female',
        startDate,
        impression: 10,
        compareBy: 'lte',
        countryCode: 'all'
      }),
    onSuccess: (res) => setLte10FemaleStats(res),
    enabled: false
  })

  const nonTrfemaleQueryLte10 = useQuery({
    queryKey: ['nonTr', 'impression', 'female', 'lte10'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Female',
        startDate,
        impression: 10,
        compareBy: 'lte',
        countryCode: 'Tr'
      }),
    onSuccess: (res) => setNonTrLte10FemaleStats(res),
    enabled: false
  })

  const nonTrMaleGte20 = useQuery({
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Male',
        startDate,
        impression: 20,
        compareBy: 'gte',
        countryCode: 'TR'
      }),
    onSuccess: (res) => setGte20NonTrMaleStats(res),
    enabled: false
  })

  const nonTrFemaleQueryLte0 = useQuery({
    queryKey: ['nonTr', 'impression', 'female', 'lte0'],
    queryFn: () =>
      Requests.getImpressionCountByGender({
        gender: 'Female',
        startDate,
        impression: 0,
        compareBy: 'lte',
        countryCode: 'TR'
      }),
    onSuccess: (res) => setLte0NonTrFemaleStats(res),
    enabled: false
  })
  const onChangeDate = (date) => {
    setstartDate(date)
  }

  useEffect(() => {
    maleQuery.refetch()
    femaleQuery.refetch()
    maleQueryGte10.refetch()
    femaleQueryLte10.refetch()
    nonTrMaleGte20.refetch()
    nonTrFemaleQueryLte0.refetch()
    nonTrMaleQueryGte10.refetch()
    nonTrfemaleQueryLte10.refetch()
  }, [])

  useEffect(() => {
    if (startDate) {
      maleQuery.refetch()
      femaleQuery.refetch()
      maleQueryGte10.refetch()
      femaleQueryLte10.refetch()
      nonTrMaleGte20.refetch()
      nonTrFemaleQueryLte0.refetch()
      nonTrMaleQueryGte10.refetch()
      nonTrfemaleQueryLte10.refetch()
    }
  }, [startDate])

  return (
    <Container fluid>
      <div className="col col-sm-col-md-3 mb-5">
        <label style={{ marginLeft: '10px' }}>Şu Tarihten Sonra</label>
        <div className="input-group">
          <ReactDatePicker
            className="form-control digits btn-pill"
            selected={startDate}
            preventOpenOnFocus
            dateFormat="dd/MM/yyyy"
            onChange={onChangeDate}
          />
        </div>
      </div>

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 20`den fazla', maleStats.userCount],
          ['Impression 20`den az', maleStats.total - maleStats.userCount]
        ]}
        options={{
          title: 'Erkekler İçin Impression Sayısı 20den fazla',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />
      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 20`den fazla', gte20NonTrMaleStats.userCount],
          ['Impression 20`den az', lte10FemaleStats.total - gte20NonTrMaleStats.userCount]
        ]}
        options={{
          title: 'Türk Olmayan Erkekler İçin Impression Sayısı 20den Fazla',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 10`dan fazla', gte10maleStats.userCount],
          ['Impression 10`dan az', gte10maleStats.total - gte10maleStats.userCount]
        ]}
        options={{
          title: 'Erkekler İçin Impression Sayısı 10dan Fazla',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />
      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 10`dan fazla', nonTrGte10maleStats.userCount],
          ['Impression 10`dan az', nonTrGte10maleStats.total - nonTrGte10maleStats.userCount]
        ]}
        options={{
          title: 'Türk Olmayan Erkekler için Impression Sayısı 10dan Fazla',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 10`dan az', lte10FemaleStats.userCount],
          ['Impression 10`dan fazla', lte10FemaleStats.total - lte10FemaleStats.userCount]
        ]}
        options={{
          title: 'Kadınlar İçin Impression Sayısı 10dan Az',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 10`dan az', nonTrLte10FemaleStats.userCount],
          ['Impression 10`dan az', nonTrLte10FemaleStats.total - nonTrLte10FemaleStats.userCount]
        ]}
        options={{
          title: 'Türk Olmayan Kadınlar İçin Impression Sayısı 10dan Az',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 0`dan az', femaleStats.userCount],
          ['Impression 0`dan fazla', femaleStats.total - femaleStats.userCount]
        ]}
        options={{
          title: 'Kadınlar İçin Impression Sayısı 0dan Az',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />

      <Chart
        width="100%"
        height="320px"
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={[
          ['Task', 'Hours per Day'],
          ['Impression 0`dan az', lte0NonTrFemaleStats.userCount],
          ['Impression 0`dan fazla', lte0NonTrFemaleStats.total - lte0NonTrFemaleStats.userCount]
        ]}
        options={{
          title: 'Türk Olmayan Kadınlar İçin Impression Sayısı 0dan Az',
          colors: ['red', 'green'],
          is3D: true
        }}
        rootProps={{ 'data-testid': '2' }}
      />
    </Container>
  )
}
