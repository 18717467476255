import React, { Fragment } from 'react'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import SweetAlert from 'sweetalert2'
import { useMutation } from '@tanstack/react-query'
import * as Requests from '../../utils/requests'
const RibbonsAddCharmful = ({
  image,
  name,
  lastActivity,
  type = 'Charmful',
  userID,
  user,
  profilesForTaging,
  setProfilesForTaging
}) => {
  const addCharmfulMutate = useMutation(Requests.addCharmful, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Etiket Eklendi.', 'success')
      setProfilesForTaging(profilesForTaging.filter((usr) => usr._id !== userID))
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const addTag = () => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('accessToken')
    }

    SweetAlert.fire({
      title: `${name} adlı kullanıcıya etiket ekle?`,
      text: 'Kullanıcıya charmful etiketini verir.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        addCharmfulMutate.mutate({
          userID,
          body: {
            tag: 'Charmful'
          }
        })
      }
    })
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="14" xl="4">
            <Card className="ribbon-wrapper" style={{ width: '350px' }}>
              <CardBody>
                <img
                  src={image}
                  alt=""
                  style={{
                    width: '150px',
                    height: '150px',
                    borderRadius: '10px',
                    marginBottom: '30px'
                  }}
                />
                <h6 style={{ marginTop: '10px' }}>{name}</h6>
                <p>{lastActivity}</p>
                <Button className="btn-air-primary" color="primary" onClick={addTag}>
                  {'Etiketi Ekle'}
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default RibbonsAddCharmful
