import axios from 'axios'

export const fetchProductApi = () => axios.get(`/api/product.json`)

export const fetchChatApi1 = () => axios.get(`/api/chatMember.json`)

export const fetchChatApi2 = () => axios.get(`/api/chat.chats.json`)

export const fetchEmailApi = () => axios.get(`/api/email.json`)

export const fetchBookmaekApi = () => axios.get(`/api/bookmark.json`)

export const fetchTodoApi = () => axios.get(`/api/todo.json`)

export const fetchTaskApi = () => axios.get(`/api/task.json`)

export const fetchProjectApi = () => axios.get(`/api/project.json`)
