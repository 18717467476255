import React, { Fragment, useEffect, useState } from 'react'

import axios from 'axios'
import { ToastContainer } from 'react-toastify'

import TabLineCustomForTickets from '../components/custom/tabLineCustomForTickets'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'

const AppLayout = ({ children, classNames, ...rest }) => (
  <>
    {/* <Loader /> */}
    <Taptop />
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header />
      <div className="page-body-wrapper">
        <Sidebar />
        <div className="page-body">
          <div>
            <Breadcrumbs title="Ticketlar" />
            <TabLineCustomForTickets />
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ToastContainer />
  </>
)

export default AppLayout
