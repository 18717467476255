import React from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ModalWithInputDate = ({
  isOpen,
  setIsOpen,
  setInput,
  deviceBan,
  ban,
  title,
  startDate,
  setstartDate = () => {},
}) => {
  const toogle = () => setIsOpen(!isOpen);

  return (
    <Modal isOpen={isOpen} toggle={toogle}>
      <ModalHeader toggle={toogle}>{title}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="col-form-label" for="message-text">
              {title}
            </Label>
            <Input
              type="textarea"
              className="form-control"
              id="message-text"
              onChange={(e) => setInput(e.target.value)}
            ></Input>
            <Label className="col-form-label" for="message-text">
              {"Şu tarihe kadar"}
            </Label>
            <ReactDatePicker
              className="form-control digits"
              selected={startDate}
              preventOpenOnFocus
              dateFormat="dd/MM/yyyy"
              onChange={(date) => setstartDate(date)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={ban}>
          {"Ban"}
        </Button>
        <Button color="secondary" onClick={deviceBan}>
          {"Device Ban"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalWithInputDate;
