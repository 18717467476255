import React from 'react'

import { PaginationItem, PaginationLink } from 'reactstrap'

const PaginationCustom = ({ currentPage, getNewPage, pageNumb }) => (
  <div>
    <PaginationItem active={currentPage === pageNumb}>
      <PaginationLink onClick={getNewPage}>{pageNumb}</PaginationLink>
    </PaginationItem>
  </div>
)

export default PaginationCustom
