import React from 'react'

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary
} from '@tanstack/react-query'
import { toast, Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { createBrowserRouter, createSearchParams, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Routers from './route'
import store from './store'

const queryCache = new QueryCache({
  onError: (error) => {
    if (error.response.status === 401) {
      toast.error('Need to logged in.')
      if (window.location.pathname === '/login') return
      router.navigate({
        pathname: 'login',
        search: createSearchParams({
          redirect: window.location.pathname
        }).toString()
      })
    }
  }
})

const mutationCache = new MutationCache({
  onError: (error) => {
    if (error.response.status === 401) {
      toast.error('Need to logged in.')
      if (window.location.pathname === '/login') return
      router.navigate({
        pathname: 'login',
        search: createSearchParams({
          redirect: window.location.pathname
        }).toString()
      })
    }
  }
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false
    }
  },
  mutationCache,
  queryCache
})

const router = createBrowserRouter([
  // match everything with "*"
  { path: '*', element: <Routers /> }
])

const App = () => (
  <div className="App">
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RouterProvider router={router} />
        <ToastContainer />
        <Toaster />
      </Provider>
    </QueryClientProvider>
  </div>
)

export default App
