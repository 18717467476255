import React, { Fragment, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { Button, Form, Image } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import { FakeUsersRibbon } from '../components/custom/FakeUsersRibbon'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

export const FakeUsers = ({ children, classNames, ...rest }) => {
  const [fakeUsers, setFakeUsers] = useState([])
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const [searchedUser, setSearchedUser] = useState(null)

  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)

  const mutation = useMutation(Requests.getAdminUsers, {
    onSuccess: async (response) => {
      setFakeUsers(response)
    }
  })

  const searchMutation = useMutation(Requests.getUserByPhoneOrMail, {
    onSuccess: async (response) => {
      setSearchedUser(response)
    }
  })

  const addAdminRoleMutate = useMutation(Requests.updateUserRole, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Kullanıcı Eklendi.', 'success')
      mutation.mutate()
      setVerticalcenter(false)
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  useEffect(() => {
    mutation.mutate()
  }, [])

  const addFakeUser = () => {
    setVerticalcenter(true)
  }

  let timeoutId
  const searchContentOnChange = (e) => {
    clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      searchMutation.mutate(e.target.value)
    }, 700)
  }

  const addNewFakeUser = () => {
    addAdminRoleMutate.mutate({ userId: searchedUser._id, role: ['Admin', 'User'] })
  }

  return (
    <>
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Fake Users" />
              <Button style={{ width: '20%' }} onClick={addFakeUser}>
                Fake User Ekle
              </Button>
              <div className="row row-cols-auto mt-4">
                {fakeUsers.map((e) => (
                  <FakeUsersRibbon user={e.user} mutation={mutation} />
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />

      <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle}>
        <ModalHeader toggle={Verticalcentermodaltoggle}>Fake Kullanıcı Ekle</ModalHeader>
        <ModalBody>
          <div>
            <Form.Label>Mail veya Telefon</Form.Label>
            <Form.Control id="userName" onChange={searchContentOnChange} />
          </div>

          {searchedUser && (
            <div className="mt-5">
              <Image src={searchedUser.extra.profilePic} width={100} height={100} />
              <h5>
                {searchedUser.name}, {moment().diff(searchedUser?.extra?.birthInfoAsDate, 'years')}
              </h5>
              <h5>{searchedUser?.extra?.location?.country}</h5>
              <Button style={{ width: '20%' }} variant="success" onClick={addNewFakeUser}>
                Ekle
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  )
}
