import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ModalNoInput = ({
  isOpen,
  setIsOpen,
  applyHandler,
  title,
  desc,
  userInformModal = false,
  data,
}) => {
  const toogle = () => setIsOpen(!isOpen);
  return (
    <Modal isOpen={isOpen} toggle={toogle}>
      <ModalHeader toggle={toogle}>{title}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            {userInformModal && data ? (
              <>
                <div>
                  <h5>Kullanıcı bilgileri</h5>
                  <p>Mail adresi: {data.user?.mail || "yok"}</p>
                  <p>Telefon numarası: {data.user?.phone || "yok"}</p>
                  <p>Biyografi: {data.profile?.bio || "yok"}</p>
                  <p>Son aktivite: {data.profile?.lastActivityDate || "yok"}</p>
                </div>
                <div>
                  <h5>Doğum haritası bilgileri</h5>
                  <p>
                    Chart linki:{" "}
                    <a target="_blank" href={data.birth.chartPicture || ""}>
                      Görüntülemek için tıklayın
                    </a>
                  </p>
                  <p>Doğum tarihi: {data.birth?.date || "yok"}</p>
                  <p>Timezone: {data.birth?.timezone || "yok"}</p>
                  <p>Timezone sonrası: {data.birth?.withTimezone || "yok"}</p>
                  <p>Doğum yeri: {data.birth?.location || "yok"}</p>
                </div>
                <div>
                  <h5>Cüzdan bilgileri</h5>
                  <p>Statüs: {data.wallet?.status || "yok"}</p>
                  <p>Meteor: {data.wallet?.meteorite || "yok"}</p>
                </div>
                <div>
                  <h5>Cihaz bilgileri</h5>
                  <p>Model: {data.user.extra?.deviceInfo?.model || "yok"}</p>
                  <p>
                    Versiyon: {data.user.extra?.deviceInfo?.version || "yok"}
                  </p>
                </div>
              </>
            ) : (
              <Label className="col-form-label" for="message-text">
                {desc}
              </Label>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toogle}>
          {"Vazgeç"}
        </Button>
        <Button color="primary" onClick={applyHandler}>
          {"Onayla"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalNoInput;
