import React, { Fragment } from 'react'

import { useParams } from 'react-router'
import { ToastContainer } from 'react-toastify'

import EditNotify from '../components/custom/EditNotifiy'
import TabLineForSaveNotify from '../components/custom/tabLineCustomForSaveNotif'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'

const EditNotification = ({ children, classNames, ...rest }) => {
  const { id } = useParams()

  return (
    <>
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Bildirimi Düzenle" />
              <EditNotify id={id} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default EditNotification
