import {
  Home,
  Users,
  List,
  Save,
  Database,
  UserX,
  Search,
  FileText,
  CheckCircle,
  MessageSquare,
  Bell,
  Type,
  Activity,
  Heart
} from 'react-feather'

export const MENUITEMS = [
  {
    Items: [
      {
        title: 'Ana Sayfa',
        icon: Home,
        type: 'link',
        path: `/index/Dubai`
      },
      {
        title: 'Aboneler',
        icon: Users,
        type: 'link',
        path: `/venus/Dubai`
      },
      {
        title: 'Banlı Kullanıcılar',
        icon: UserX,
        type: 'link',
        path: `/banneds/Dubai`
      },
      {
        title: 'Kullanıcı Etiketleri',
        icon: Users,
        type: 'link',
        path: `/profile-tags/Dubai`
      },
      {
        title: 'Son Kayıt Olanlar',
        icon: Users,
        type: 'link',
        path: `/last-registered-users/Dubai`
      },
      {
        title: 'Muhtemel Fake Kullanıcılar',
        icon: Users,
        type: 'link',
        path: `/possible-fake-users/Dubai`
      },
      {
        title: 'Banlanan Fake Kullanıcılar',
        icon: Users,
        type: 'link',
        path: `/banned-fake-users/Dubai`
      },
      {
        title: 'Fake Users',
        icon: Users,
        type: 'link',
        path: `/fake-users/Dubai`
      },
      {
        title: 'Like',
        icon: Heart,
        type: 'link',
        path: `/send-like/Dubai`
      }
    ]
  },
  {
    menutitle: 'Global İstatistikler',
    menucontent: 'Kullanıcılarla ilgili ülke bazında veya genel istatistiki bilgiler',
    Items: [
      {
        title: 'İngilizce',
        icon: Type,
        type: 'link',
        path: `/english-users/Dubai`
      },
      {
        title: 'İspanyolca',
        icon: Type,
        type: 'link',
        path: `/spanish-users/Dubai`
      },
      {
        title: 'Portekizce',
        icon: Type,
        type: 'link',
        path: `/portuguese-users/Dubai`
      },
      {
        title: 'Impressions',
        icon: Activity,
        type: 'link',
        path: `/impression-stats/Dubai`
      },
      {
        title: 'Permission',
        icon: Activity,
        type: 'link',
        path: `/permission-stats/Dubai`
      }
    ]
  },

  {
    menutitle: 'Destek',
    menucontent: 'Kullanıcılar için çeşitli destek sistemleri',
    Items: [
      {
        title: 'Arama',
        icon: Search,
        type: 'link',
        path: `/search-ticket/Dubai`
      },
      {
        title: 'Ticketlar',
        icon: List,
        type: 'link',
        path: `/tickets/Dubai`
      },
      {
        title: 'Raporlar',
        icon: FileText,
        type: 'link',
        path: `/reports/Dubai`
      },
      {
        title: 'Doğrulama İstekleri',
        icon: CheckCircle,
        type: 'link',
        path: `/verification-requests/Dubai`
      },
      {
        title: 'Mesajlaşma Ekranı',
        icon: MessageSquare,
        type: 'link',
        path: `/chats/Dubai`
      },
      {
        title: 'Ban Ticket',
        icon: List,
        type: 'link',
        path: '/ticket/ban'
      }
    ]
  },
  {
    menutitle: 'Bildirim',
    menucontent: 'Kullanıcılara bildirim gönderme',
    Items: [
      {
        title: 'Bildirim gönder',
        icon: Bell,
        type: 'link',
        path: `/push-notification/Dubai`
      },
      {
        title: 'Bildirim kaydet',
        icon: Save,
        type: 'link',
        path: `/save-notification/Dubai`
      },
      {
        title: 'Kayıtlı bildirimler',
        icon: Database,
        type: 'link',
        path: `/saved-notification/Dubai`
      }
    ]
  }
]
