import React, { useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import { Form, FormGroup, Input, Label, CardBody, Button, Col } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import { LikeRibbon } from '../components/custom/LikeRibbon'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

export const Like = ({ children, classNames, ...rest }) => {
  const [fakeUsers, setFakeUsers] = useState([])

  const [limit, setLimit] = useState(10000)
  const [timeInMs, setTimeInMs] = useState(600000)
  const [gender, setGender] = useState('Male')
  const [hasRequestLessThan, setHasRequestLessThan] = useState(15)
  const [countryCodes, setCountryCodes] = useState([
    'TR',
    'US',
    'DZ',
    'LY',
    'EG',
    'SY',
    'IQ',
    'JO',
    'SA',
    'KA',
    'AE',
    'KW',
    'IR',
    'AF',
    'PK',
    'KZ',
    'UZ',
    'TM',
    'KG',
    'TJ',
    'CO',
    'VE',
    'PE',
    'EC',
    'CL',
    'BO',
    'US',
    'PY',
    'AR',
    'UY',
    'DE',
    'AT',
    'BE',
    'BG',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'HR',
    'NL',
    'IE',
    'ES',
    'SE',
    'IT',
    'LV',
    'LT',
    'LU',
    'HU',
    'MT',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'GR',
    'IS',
    'NO',
    'CH',
    'GB',
    'CA',
    'DO'
  ])

  const mutation = useMutation(Requests.getRandomFakeUsers, {
    onSuccess: async (response) => {
      setFakeUsers(response)
    }
  })

  useEffect(() => {
    mutation.mutate()
  }, [])

  const sendLike = useMutation(Requests.sendLike, {
    onSuccess: async (response) => {},
    onError: (e) => {}
  })

  const onSendPress = (userId) => {
    const body = {
      userId,
      limit,
      timeMs: timeInMs,
      gender,
      gotRequestLessThan: hasRequestLessThan,
      countryCode: countryCodes
    }

    SweetAlert.fire({
      title: 'Seçilen Kişi ile like göndermek istiyor musunuz?',
      cancelButtonText: 'Hayır',
      confirmButtonText: 'Evet',
      reverseButtons: true,
      showCancelButton: true
    }).then((res) => {
      if (res.isConfirmed) {
        SweetAlert.fire('Başarılı!', 'Like yollanmaya başlandı', 'success')
        sendLike.mutate(body)
      }
    })
  }

  const onRefresh = () => {
    window.scrollTo({ top: 300, left: 0, behavior: 'smooth' })

    mutation.mutate()
  }

  return (
    <>
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Like" />

              <CardBody className="mw-30">
                <Form className="theme-form form-label-align-right range-slider">
                  <FormGroup>
                    <Label for="formLimit">Limit</Label>
                    <Input
                      type="number"
                      id="formLimit"
                      value={limit}
                      onChange={(e) => setLimit(Number(e.target.value))}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="formTimeInMs">Time in ms</Label>
                    <Input
                      type="number"
                      id="formTimeInMs"
                      value={timeInMs}
                      onChange={(e) => setTimeInMs(Number(e.target.value))}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="formGender">Gender</Label>
                    <Input
                      type="select"
                      id="formGender"
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="formHasRequestLessThan">Has request less than</Label>
                    <Input
                      type="number"
                      id="formHasRequestLessThan"
                      value={hasRequestLessThan}
                      onChange={(e) => setHasRequestLessThan(Number(e.target.value))}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="formCountryCodes">Country Codes</Label>
                    <Input
                      type="textarea"
                      id="formCountryCodes"
                      value={countryCodes.join(', ')}
                      onChange={(e) => setCountryCodes(e.target.value.split(', '))}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <div className="row row-cols-auto mt-4">
                {!fakeUsers.length ? (
                  <div className="d-flex justify-content-center col-sm p-3">
                    <Col md="3">
                      <h6 className="sub-title mb-0 text-center">Loading</h6>
                      <div className="loader-box">
                        <div className="loader-30" />
                      </div>
                    </Col>
                  </div>
                ) : (
                  fakeUsers.map((e) => <LikeRibbon key={e._id} user={e} onSend={onSendPress} />)
                )}
              </div>

              <div className="d-flex justify-content-center col-sm p-3">
                <Button
                  variant="success"
                  size="large"
                  style={{ marginBottom: '10px', width: '50%', marginRight: '10px', height: '10%' }}
                  onClick={onRefresh}
                >
                  Yenile
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
