import React, { Fragment, useEffect, useState } from 'react'

import { Checkbox } from '@mobiscroll/react-lite'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FormSelect } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { Container, Button, FormGroup, Label, Input } from 'reactstrap'

import { Language } from '../../constant'
import * as Requests from '../../utils/requests'

const EditNotify = (id) => {
  const [header, setHeader] = useState('')
  const [content, setContent] = useState('')
  const [sign, setSign] = useState('Ari')
  const [planet, setPlanet] = useState('ascendant')
  const [startDate, setstartDate] = useState(new Date())
  const [lockDate, setLockDate] = useState(false)
  const [contentType, setContentType] = useState('')
  const [lang, setLang] = useState('tr-TR')
  const [time, setTime] = useState('')

  const navigate = useNavigate()
  const targetID = id.id.slice(1, id.id.lenght)

  const getNotificationByID = useMutation(Requests.getNotificationByID, {
    onSuccess: async (response) => (
      setContentType(response.notification.contentType),
      setSign(response.notification.zodiacSign),
      setPlanet(response.notification.planet),
      setstartDate(new Date(response.notification.publishDate)),
      setHeader(response.notification.title),
      setHeader(response.notification.notificationTitle),
      setContent(response.notification.content)
    ),
    onError: (e) => {
      alert('Bildirim verisi çağırılırken bir hata oluştu')
    }
  })

  const editNotification = useMutation(Requests.editNotification, {
    onSuccess: async (response) => {
      navigate('index/Dubai')
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  useEffect(() => {
    getNotificationByID.mutate({ targetID })
  }, [])

  const lockDateCheckBox = () => {
    setLockDate(!lockDate)
  }

  const createNotification = async () => {
    const dateValue = new Date(startDate)
    const timezoneOffset = dateValue.getTimezoneOffset() * 60000

    editNotification.mutate({
      targetID,
      body: {
        contentType: 'Daily',
        zodiacSign: sign,
        planet: planet.toLowerCase(),
        publishDate: new Date(dateValue.getTime() - timezoneOffset),
        title: header || 'test',
        subtitle: header || 'Merhaba',
        notificationTitle: header || 'Merhaba',
        content,
        languageCode: Language,
        ...(lockDate && { highPriority: true })
      }
    })
  }

  const languageHandler = (e) => {
    setLang(e.target.value)
  }

  const timeHandler = (e) => {
    setTime(e.target.value)
  }

  return (
    <Container fluid>
      <FormGroup>
        <div>
          <div className="row">
            <div className="col col-sm-col-md-1">
              <Label style={{ marginLeft: '10px' }}>Hitap</Label>
              <Input
                className="form-control btn-pill"
                id="header"
                value={header}
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
            <div className="col col-sm-col-md-3">
              <label style={{ marginLeft: '10px' }}>Tarih</label>
              <div className="input-group">
                <ReactDatePicker
                  className="form-control digits btn-pill"
                  selected={startDate}
                  preventOpenOnFocus
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => setstartDate(date)}
                />
                <div
                  style={{
                    marginLeft: '10px',
                    marginTop: '10px',
                    alignItems: 'center'
                  }}
                >
                  <Input
                    className="checkbox_animated"
                    id="chk-ani"
                    type="checkbox"
                    onChange={lockDateCheckBox}
                  />
                  {Option}
                  <Label style={{ marginLeft: '5px' }}>Tarihi Kilitle</Label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-">
            <div>
              <Label>İçerik</Label>
              <Input
                type="textarea"
                className="form-control btn-pill-less"
                rows="3"
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: '40px' }}>
            <div className="col col-xs">
              <Label>İçerik Türü</Label>
              <div className="col-">
                <Label for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="daily"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                    onChange={(e) => timeHandler(e)}
                  />
                  {Option} Daily
                </Label>
                <div>
                  <Label for="edo-ani1">
                    <Input
                      className="radio_animated"
                      id="weekly"
                      type="radio"
                      name="rdo-ani"
                      onChange={(e) => timeHandler(e)}
                    />
                    {Option} Weekly
                  </Label>
                </div>
                <Label for="edo-ani2">
                  <Input
                    className="radio_animated"
                    id="monthly"
                    type="radio"
                    name="rdo-ani"
                    onChange={(e) => timeHandler(e)}
                  />
                  {Option} Monthly
                </Label>
              </div>
            </div>
            <div className="col col-xs">
              <Label>Dil</Label>
              <div>
                <Label for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="ig-language"
                    type="radio"
                    value="tr-TR"
                    name="languageRadioOptions"
                    onChange={(e) => languageHandler(e)}
                    defaultChecked
                  />
                  {Option} Türkçe
                </Label>
                <div>
                  <Label for="edo-ani1">
                    <Input
                      className="radio_animated"
                      id="ig-language"
                      type="radio"
                      name="languageRadioOptions"
                      value="en-EN"
                      onChange={(e) => languageHandler(e)}
                    />
                    {Option} English
                  </Label>
                </div>
                <Label for="edo-ani2">
                  <Input
                    className="radio_animated"
                    id="ig-language"
                    type="radio"
                    name="languageRadioOptions"
                    value="es-ES"
                    onChange={(e) => languageHandler(e)}
                  />
                  {Option} Spanish
                </Label>
              </div>
            </div>
            <div className="col col-xs">
              <Label>Burç</Label>
              <FormSelect
                aria-label="Default select example"
                onChange={(opt) => setSign(opt.target.value)}
                defaultChecked
                value={sign}
              >
                <option value="Ari">Aries</option>
                <option value="Tau">Taurus</option>
                <option value="Gem">Gemini</option>
                <option value="Can">Cancer</option>
                <option value="Leo">Leo</option>
                <option value="Vir">Virgo</option>
                <option value="Lib">Libra</option>
                <option value="Sco">Scorpio</option>
                <option value="Sag">Sagittarius</option>
                <option value="Cap">Capricorn</option>
                <option value="Aqu">Aquarius</option>
                <option value="Pis">Pisces</option>
              </FormSelect>
            </div>
            <div className="col col-xs">
              <Label>Gezegen</Label>
              <FormSelect
                aria-label="Default select example"
                onChange={(opt) => setSign(opt.target.value)}
                value={planet}
              >
                <option value="ascendant">Ascendant</option>
                <option value="sun">Sun</option>
                <option value="moon">Moon</option>
                <option value="mercury">Mercury</option>
                <option value="mars">Mars</option>
                <option value="venus">Venus</option>
                <option value="jupiter">Jupiter</option>
                <option value="saturn">Saturn</option>
                <option value="uranus">Uranus</option>
                <option value="neptune">Neptune</option>
                <option value="pluto">Pluto</option>
              </FormSelect>
            </div>
          </div>
          <div
            className="d-grid gap-2"
            style={{
              marginTop: '40px',
              maxWidth: '100%'
            }}
          >
            <Button color="primary" style={{ alignSelf: 'center' }} onClick={createNotification}>
              Bildirimi Kaydet
            </Button>
          </div>
        </div>
      </FormGroup>
    </Container>
  )
}

export default EditNotify
