import instance, { taskInstance } from './axios'

export const login = async ({ phoneNumber, password }) => {
  const { data } = await instance.post('/v1/auth/login', {
    phoneNumber,
    password
  })

  return data
}

export const loginRecords = async ({ body }) => {
  const { data } = await instance.post('/v1/admin/login-records', body)

  return data
}

export const getVenus = async () => {
  const { data } = await instance.get('/v1/admin/subscribers')

  return data
}

export const searchTicket = async ({ searchInput }) => {
  const { data } = await instance.get(`/v1/ticket/user-tickets/${searchInput}`)

  return data
}

export const getBanneds = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(`/v1/admin/banned-users?page=${nextPage}&limit=${nextLimit}`)

  return data
}

export const getNewClosedTickets = async ({ index, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/ticket/list-tickets/closeds?page=${index}&limit=${nextLimit}`
  )

  return data
}

export const getNewOpenTickets = async ({ index, nextLimit }) => {
  const { data } = await instance.get(`/v1/ticket/list-tickets?page=${index}&limit=${nextLimit}`)

  return data
}

export const getClosedTickets = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/ticket/list-tickets/closeds?page=${nextPage}&limit=${nextLimit}`
  )

  return data
}

export const getOpenTickets = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(`/v1/ticket/list-tickets?page=${nextPage}&limit=${nextLimit}`)

  return data
}

export const getOpenReports = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(`/v1/ticket/list-reports?page=${nextPage}&limit=${nextLimit}`)

  return data
}

export const getClosedReports = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/ticket/list-reports/closeds?page=${nextPage}&limit=${nextLimit}`
  )

  return data
}

export const getNewOpenReports = async ({ index, nextLimit }) => {
  const { data } = await instance.get(`/v1/ticket/list-reports?page=${index}&limit=${nextLimit}`)

  return data
}

export const getNewClosedReports = async ({ index, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/ticket/list-reports/closeds?page=${index}&limit=${nextLimit}`
  )

  return data
}

export const getCharmfuls = async ({ nextPage, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/admin/charmful-profiles?page=${nextPage}&limit=${nextLimit}`
  )

  return data
}

export const getNewCharmfuls = async ({ index, nextLimit }) => {
  const { data } = await instance.get(
    `/v1/admin/charmful-profiles?page=${index}&limit=${nextLimit}`
  )

  return data
}

export const getProfilesForCharmful = async () => {
  const { data } = await instance.get(`/v1/admin/profiles-for-tagging`)

  return data
}

export const sendNotify = async (body) => {
  const { data } = await instance.post(`/v1/notification/push`, body)

  return data
}

export const startChat = async ({ ticketID }) => {
  const { data } = await instance.put(`/v1/ticket/start-chat/${ticketID}`)

  return data
}

export const closeTicket = async ({ ticketID, operation }) => {
  const { data } = await instance.put(`/v1/ticket/close/${ticketID}`, operation)

  return data
}

export const givePremium = async ({ ticketID }) => {
  const { data } = await instance.put(`/v1/ticket/set-as-premium/${ticketID}`)

  return data
}

export const deleteAccount = async ({ phoneNumber }) => {
  const { data } = await instance.post(`/v1/admin/delete-account`, phoneNumber)

  return data
}

export const warnUser = async ({ ticketID, body }) => {
  const { data } = await instance.put(`/v1/ticket/warn-reported/${ticketID}`, body)

  return data
}

export const getChat = async ({ ticketID }) => {
  const { data } = await instance.get(`/v1/ticket/get-chat/${ticketID}`)

  return data
}

export const banFromShuffle = async ({ ticketID }) => {
  const { data } = await instance.put(`/v1/ticket/set-as-hidden/${ticketID}`)

  return data
}

export const normalBan = async ({ ticketID, body }) => {
  const { data } = await instance.put(`/v1/ticket/oldban/${ticketID}`, body)

  return data
}

export const deviceBan = async ({ ticketID, banReason }) => {
  const { data } = await instance.put(`/v1/ticket/perma/${ticketID}`, banReason)

  return data
}

export const reopenTicket = async ({ ticketID }) => {
  const { data } = await instance.put(`/v1/ticket/re-open/${ticketID}`)

  return data
}

export const removeCharmful = async ({ userID, body }) => {
  const { data } = await instance.put(`/v1/admin/remove-tag/${userID}`, body)

  return data
}

export const removeBan = async ({ ticketID }) => {
  const { data } = await instance.put(`/v1/admin/remove-ban/${ticketID}`)

  return data
}

export const addCharmful = async ({ userID, body }) => {
  const { data } = await instance.put(`/v1/admin/add-tag/${userID}`, body)

  return data
}

export const removeVenus = async ({ userID }) => {
  const { data } = await instance.post(`/v1/admin/remove-premium/${userID}`)

  return data
}

export const getSavedNotifies = async ({ pendingPage }) => {
  const { data } = await instance.get(`/v1/notification/list?page=${pendingPage}&limit=80`)

  return data
}

export const getPendingNotifies = async ({ pendingPage }) => {
  const { data } = await instance.get(
    `/v1/notification/list?pendings=true&page=${pendingPage}&limit=40`
  )

  return data
}

export const approveNotify = async ({ id }) => {
  const { data } = await instance.put(`/v1/notification/approve/${id}`)

  return data
}

export const denyNotify = async ({ id }) => {
  const { data } = await instance.put(`/v1/notification/deny/${id}`)

  return data
}

export const getUserCount = async () => {
  const { data } = await instance.get(`/v1/admin/user-count`)

  return data
}

export const getSubCount = async () => {
  const { data } = await instance.get(`/v1/admin/subscriber-count`)

  return data
}

export const getMeteroiteCount = async () => {
  const { data } = await instance.get(`/v1/admin/meteorite-purchase-count`)

  return data
}

export const getSubPurchaseCount = async () => {
  const { data } = await instance.get(`/v1/admin/active-subscriber-count`)

  return data
}

export const getNotificationByID = async ({ targetID }) => {
  const { data } = await instance.get(`/v1/notification/${targetID}`)

  return data
}

export const editNotification = async ({ targetID, body }) => {
  const { data } = await instance.put(`/v1/notification/update/${targetID}`, body)

  return data
}

export const createNotification = async ({ body }) => {
  const { data } = await instance.post(`/v1/notification/`, body)

  return data
}

export const getChats = async ({ nextPage }) => {
  const { data } = await instance.get(`/v1/admin/get-chats?nextPage=${nextPage}`)

  return data
}

export const getCurrentChat = async ({ chatID }) => {
  const { data } = await instance.get(`/v1/admin/get-chat-messages/${chatID}`)

  return data
}

export const showUserInfo = async ({ userID }) => {
  const { data } = await instance.get(`/v1/admin/user-details/?id=${userID}`)

  return data
}

export const getCountByGender = async ({ gender }) => {
  const { data } = await instance.get(`/v1/admin/users-by-gender`, {
    params: {
      gender
    }
  })

  return data
}

export const getCountByGenderAndLanguage = async ({ gender, language }) => {
  const { data } = await instance.get(`/v1/admin/users-by-gender-and-language`, {
    params: {
      gender,
      language
    }
  })

  return data
}

export const getCountByLanguage = async ({ language }) => {
  const { data } = await instance.get(`/v1/admin/users-by-language`, {
    params: {
      language
    }
  })

  return data
}

export const getFreezedCountByGender = async ({ gender, language }) => {
  const { data } = await instance.get(`/v1/admin/total-freezed-gender`, {
    params: {
      gender,
      language
    }
  })

  return data
}

export const getDeletedCountByGender = async ({ gender, language }) => {
  const { data } = await instance.get(`/v1/admin/total-deleted-user`, {
    params: {
      gender,
      language
    }
  })

  return data
}

export const getDailyCreatedAt = async ({ dateMax, dateMin, language }) => {
  const { data } = await instance.get(`/v1/admin/daily-created`, {
    params: {
      dateMax,
      dateMin,
      language
    }
  })

  return data
}

export const getBanTickets = async ({ limit, skip, status }) => {
  const { data } = await instance.get('/v1/ticket/ban', {
    params: {
      limit,
      skip,
      status
    }
  })

  return data
}

export const getBanTicketDetail = async ({ id }) => {
  const { data } = await instance.get(`v1/ticket/ban/${id}`)

  return data
}

export const updateBanTicket = async ({ id, action }) => {
  const { data } = await instance.put(`v1/ticket/ban/${id}`, { action })

  return data
}

export const updateBan = async ({ id, action }) => {
  const { data } = await instance.put(`v1/admin/ban/${id}`, { action })

  return data
}

export const deleteBanTicket = async ({ id, action }) => {
  const { data } = await instance.delete(`v1/ticket/ban/${id}`, { data: { action } })

  return data
}

export const getLastRegisteredUsers = async ({ timestamp, page, limit }) => {
  const { data } = await instance.get(
    `v1/admin/users-last-registered?timestamp=${timestamp}&page=${page}&limit=${limit}`
  )

  return data
}

export const getPossibleFakeUsers = async ({ timestamp, page, limit }) => {
  const { data } = await instance.get(
    `v1/admin/possible-fake-users?timestamp=${timestamp}&page=${page}&limit=${limit}`
  )

  return data
}

export const getBannedFakeUsers = async ({ timestamp, page, limit }) => {
  const { data } = await instance.get(
    `v1/admin/banned-fake-users?timestamp=${timestamp}&page=${page}&limit=${limit}`
  )

  return data
}

export const updateUserWithAction = async (body) => {
  const { data } = await instance.put(`v1/admin/user-actions`, body)

  return data
}

export const getUserInfos = async (userId) => {
  const { data } = await instance.get(`v1/admin/user-analytics?userId=${userId}`)

  return data
}

export const getAdminUsers = async () => {
  const { data } = await instance.get(`v1/admin/get-admin-users`)

  return data
}

export const updateUserRole = async ({ userId, role }) => {
  const { data } = await instance.put(`v1/admin/update-user-role`, { userId, role })

  return data
}

export const updateAdminUser = async ({ userId, userName, birthDateAsInfo, location }) => {
  const { data } = await instance.put(`v1/admin/update-admin-user`, {
    userId,
    userName,
    birthDateAsInfo,
    location
  })

  return data
}

export const getUserByPhoneOrMail = async (content) => {
  const { data } = await instance.get(`v1/admin/search-user?content=${content}`)

  return data
}

export const sendRequestWithAdmin = async ({ userId, targetUserId }) => {
  const { data } = await instance.post(`v1/admin/send-admin-request`, {
    userId,
    targetUserId
  })

  return data
}

export const getImpressionCountByGender = async ({
  gender,
  startDate,
  impression,
  compareBy,
  countryCode
}) => {
  const { data } = await instance.get(`/v1/admin/impression-stat`, {
    params: {
      gender,
      startDate,
      impression,
      compareBy,
      countryCode
    }
  })

  return data
}

export const getPermissionStats = async ({ gender, startDate, permission, exceptTr }) => {
  const { data } = await instance.get(`/v1/admin/permission-stat`, {
    params: {
      gender,
      startDate,
      permission,
      exceptTr
    }
  })

  return data
}

export const getVerificationRequests = async ({ page, limit, type = 0 }) => {
  const { data } = await instance.get(`/v1/admin/verification-requests`, {
    params: { page, limit, type }
  })

  return data
}

export const verificationActions = async (body) => {
  const { data } = await instance.put(`/v1/admin/verification-actions`, body)

  return data
}

export const getFakeUserInfos = async (userId) => {
  const { data } = await taskInstance.get(`admin/f-user-info?userId=${userId}`)

  return data
}

export const getRandomFakeUsers = async () => {
  const { data } = await instance.get(`/v1/admin/random-fake`)

  return data
}

export const sendLike = async (body) => {
  const { data } = await taskInstance.post(`admin/fake-match-request`, body)

  return data
}
