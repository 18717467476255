import React, { memo, useCallback, useEffect, useState } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { ToastContainer } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import { Button, Container, Spinner } from 'reactstrap'

import { BanTicketCard } from '../../components/cards/ban-ticket/BanTicketCard'
import { useElementIsVisible } from '../../hooks/useElementIsVisible'
import Breadcrumbs from '../../layout/breadcrumb'
import Footer from '../../layout/footer'
import Header from '../../layout/header'
import Sidebar from '../../layout/sidebar'
import Taptop from '../../layout/tap-top'
import * as Requests from '../../utils/requests'
import styles from './styles.module.css'

const FETCH_LIMIT = 15

const filterOptions = [
  {
    label: 'Status',
    options: [
      { value: 'Open', label: 'Açık', key: 'status' },
      { value: 'Closed', label: 'Kapalı', key: 'status' }
    ]
  }
]

const getFilterAsObject = (filterArray) => {
  const filter = filterArray.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {})

  return filter
}

const BanTicketComponent = () => {
  const navigate = useNavigate()

  const [containerRef, isVisible] = useElementIsVisible()

  const [filter, setFilter] = useState([])

  const { fetchNextPage, data, refetch, isFetching, isInitialLoading } = useInfiniteQuery({
    queryKey: ['ticket', 'ban'],
    queryFn: ({ pageParam = 0 }) =>
      Requests.getBanTickets({ limit: FETCH_LIMIT, skip: pageParam, ...getFilterAsObject(filter) }),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < FETCH_LIMIT) return undefined

      const skip = allPages.length * FETCH_LIMIT

      return skip
    }
  })

  useEffect(() => {
    fetchNextPage()
  }, [isVisible, fetchNextPage])

  const onCardClick = useCallback(
    (id) => {
      navigate(id)
    },
    [navigate]
  )

  const onChange = useCallback((newFilter) => {
    setFilter(newFilter)
  }, [])

  return (
    <>
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Ban Ticket" />
              <Container fluid className="my-4">
                <div className={classNames(styles['filter-container'], styles.flex)}>
                  <div className={styles.dropdown}>
                    <Select options={filterOptions} isMulti onChange={onChange} />
                  </div>
                  <Button color="info" onClick={refetch} className="ms-2">
                    Apply
                  </Button>
                </div>

                <div className={classNames('row', 'row-cols-3', 'gy-4', styles['mb-2'])}>
                  {data?.pages?.map((page, index, array) =>
                    page?.map((banData, childIndex, childArray) => {
                      if (array.length - 1 === index && childArray.length - 1 === childIndex) {
                        return (
                          <div
                            className="col-xl-4 col-md-6 col-12"
                            key={banData._id}
                            ref={containerRef}
                          >
                            <BanTicketCard
                              id={banData._id}
                              message={banData.message}
                              name={banData.name}
                              thumbnail={banData.thumbnail}
                              onClick={onCardClick}
                              status={banData.status}
                            />
                          </div>
                        )
                      }

                      return (
                        <div className="col-xl-4 col-md-6 col-12" key={banData._id}>
                          <BanTicketCard
                            id={banData._id}
                            message={banData.message}
                            name={banData.name}
                            thumbnail={banData.thumbnail}
                            onClick={onCardClick}
                            status={banData.status}
                          />
                        </div>
                      )
                    })
                  )}
                </div>
                {isInitialLoading && <Spinner color="primary" />}
                {isFetching && !isInitialLoading && (
                  <div className={classNames(styles.flex, styles.center)}>
                    <Spinner color="primary" />
                  </div>
                )}
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export const BanTicket = memo(BanTicketComponent)
