import React, { Fragment } from 'react'

import { ToastContainer } from 'react-bootstrap'

import { PermissionStats as PermissionStatsComponent } from '../components/custom/PermissionStats'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'

export const PermissionStats = () => (
  <>
    {/* <Loader /> */}
    <Taptop />
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <Header />
      <div className="page-body-wrapper">
        <Sidebar />
        <div className="page-body">
          <div>
            <Breadcrumbs title="Permission istatistikleri" />
            <PermissionStatsComponent />
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ToastContainer />
  </>
)
