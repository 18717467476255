import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalForChat = ({ isOpen, setIsOpen, applyHandler, title, desc }) => {
  const toogle = () => setIsOpen(!isOpen);
  return (
    <Modal isOpen={isOpen} toggle={toogle}>
      <ModalHeader toggle={toogle}>{title}</ModalHeader>
      <ModalBody>
        {desc !== null ? (
          desc?.map((message) => {
            return <p>{message}</p>;
          })
        ) : (
          <p>Mesaj Geçmişi bulunmamakta</p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toogle}>
          {"Vazgeç"}
        </Button>
        <Button color="primary" onClick={applyHandler}>
          {"Onayla"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalForChat;
