import React, { Fragment } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

const RibbonsCustomForBan = ({ image, name, duration, phoneNumb, ticketID }) => {
  const removeBanMutate = useMutation(Requests.removeBan, {
    onSuccess: async (response) => {
      SweetAlert.fire('Ban kaldırıldı!', 'Kullanıcı artık özgür', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const deleteAccountMutate = useMutation(Requests.deleteAccount, {
    onSuccess: async (response) => {
      SweetAlert.fire('Kullanıcı Silindi!', 'Hesap sonsuza kadar silindi', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const removeBan = () => {
    SweetAlert.fire({
      title: `${name} adlı kullanıcının banını kaldır?`,
      text: 'Kullanıcının banı kaldırılacaktır. ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        removeBanMutate.mutate({ ticketID })
      }
    })
  }

  const deleteAccount = () => {
    SweetAlert.fire({
      title: `${name} adlı kullanıcının hesabını sil?`,
      text: 'Kullanıcıya ait hesap sonsuza kadar silinecektir.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        deleteAccountMutate.mutate({ phoneNumber: phoneNumb })
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '500px' }}>
            <CardBody>
              <div
                className="ribbon ribbon-info ribbon-right"
                style={{
                  borderRadius: '10px',
                  backgroundColor: '#ffd6d6',
                  color: 'red',
                  marginRight: '10px'
                }}
              >
                Banned
              </div>
              <img
                src={image}
                alt=""
                style={{
                  width: '45px',
                  height: '45px',
                  borderRadius: '45px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              <p>
                {`Şu tarihe kadar uzaklaştırıldı: `}
                <b>{duration}</b>
              </p>
              <div>
                <Button
                  className="btn-air-primary"
                  color="primary"
                  onClick={removeBan}
                  style={{ marginRight: '15px' }}
                >
                  Banı Kaldır
                </Button>
                <Button className="btn-danger-gradien" color="red" onClick={deleteAccount}>
                  Hesabı Sil
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsCustomForBan
