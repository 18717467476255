import React, { memo, useCallback } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import classNamesBind from 'classnames/bind'
import moment from 'moment'
import { ToastContainer } from 'react-bootstrap'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Container } from 'reactstrap'

import DefaultButton from '../../components/buttons/default-Button'
import { DefaultButtons } from '../../constant'
import { BanTicketAction, DeleteBanTicketAction } from '../../enums/ban-ticket.enum'
import { BanAction } from '../../enums/ban.enum'
import Breadcrumbs from '../../layout/breadcrumb'
import Footer from '../../layout/footer'
import Header from '../../layout/header'
import Sidebar from '../../layout/sidebar'
import Taptop from '../../layout/tap-top'
import * as Requests from '../../utils/requests'
import styles from './styles.module.css'

import placeholderUser from '../../assets/images/placeholder-user.png'

const cx = classNamesBind.bind(styles)

const BanTicketDetailComponent = () => {
  const { id } = useParams()
  const { data, refetch, isLoading } = useQuery({
    queryKey: ['ticket', 'ban', id],
    queryFn: () => Requests.getBanTicketDetail({ id })
  })

  const updateBanTicketMutation = useMutation(Requests.updateBanTicket, {
    onSuccess: () => {
      refetch()
      toast.success('Başarılı')
    }
  })
  const updateBanMutation = useMutation(Requests.updateBan, {
    onSuccess: () => {
      refetch()
      toast.success('Başarılı')
    }
  })
  const deleteBanTicketMutation = useMutation(Requests.deleteBanTicket, {
    onSuccess: () => {
      refetch()
      toast.success('Başarılı')
    }
  })

  const onCloseTicket = useCallback(() => {
    updateBanTicketMutation.mutate({ id, action: BanTicketAction.CLOSE })
  }, [id, updateBanTicketMutation])

  const onDeactiveBan = useCallback(() => {
    updateBanMutation.mutate({ id: data?.ban?._id, action: BanAction.DEACTIVATE })
  }, [data?.ban?._id, updateBanMutation])

  const onOpenTicket = useCallback(() => {
    updateBanTicketMutation.mutate({ id, action: BanTicketAction.OPEN })
  }, [id, updateBanTicketMutation])

  const onActiveBan = useCallback(() => {
    updateBanMutation.mutate({ id: data?.ban?._id, action: BanAction.ACTIVATE })
  }, [data?.ban?._id, updateBanMutation])

  const onDeleteBanMedia = useCallback(() => {
    deleteBanTicketMutation.mutate({ id, action: DeleteBanTicketAction.DELETE_MEDIA })
  }, [id, deleteBanTicketMutation])

  return (
    <>
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Ban Ticket Detail" />
              <Container fluid>
                <div className={classNames(styles.container, styles.relative, styles['mb-2'])}>
                  <p className={classNames(styles.text, styles['f-rubik'], styles['fs-5'])}>
                    Ban Ticket ID: {data?._id}
                  </p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Mesajı
                  </p>
                  <p className={classNames(styles.text)}>{data?.message}</p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Mail Adresi
                  </p>
                  <p className={classNames(styles.text)}>{data?.mail}</p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['fs-2'],
                      styles['mt-2']
                    )}
                  >
                    User Detail
                  </p>
                  <div className={classNames(styles.topContainer, styles['mt-1'])}>
                    <img
                      src={data?.profilePic || placeholderUser}
                      alt={`ban${id}`}
                      className={styles.image}
                    />
                    <p
                      className={classNames(
                        styles.text,
                        styles['ml-1'],
                        styles['f-rubik'],
                        styles['fs-1'],
                        styles['fw-5']
                      )}
                    >
                      {data?.name}
                    </p>
                  </div>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['fs-2'],
                      styles['mt-2']
                    )}
                  >
                    Ban Detail
                  </p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Ban Nedeni
                  </p>
                  <p className={classNames(styles.text)}>{data?.ban.reason}</p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Ban Tipi
                  </p>
                  <p className={classNames(styles.text)}>{data?.ban.type}</p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Ban Bitiş Tarihi
                  </p>
                  <p className={classNames(styles.text)}>{data?.ban.duration || 'Kalıcı'}</p>
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['mt-1']
                    )}
                  >
                    Ban Durumu
                  </p>
                  <p className={classNames(styles.text)}>
                    {data?.ban.active ? 'Aktif' : 'Deaktif'}
                  </p>
                  <div className={classNames(styles.topContainer, styles['mt-1'])}>
                    <img
                      src={data?.ban.bannedBy.thumbnail || placeholderUser}
                      alt={`ban${id}`}
                      className={classNames(styles['img-2'], styles['r-circle'])}
                    />
                    <p className={classNames(styles.text, styles['ml-05'])}>
                      {data?.ban.bannedBy.name} tarafından{' '}
                      {moment(data?.ban.bannedAt).format('D, MMMM YYYY / H:mm a')} tarihinde
                      banlandı
                    </p>
                  </div>
                  {data?.ban?.extra && (
                    <p
                      className={classNames(
                        styles.text,
                        styles['fw-5'],
                        styles['f-rubik'],
                        styles['fs-2'],
                        styles['mt-2']
                      )}
                    >
                      Ban Extra
                    </p>
                  )}
                  {data?.ban?.extra?.media && (
                    <>
                      <p
                        className={classNames(
                          styles.text,
                          styles['fw-5'],
                          styles['f-rubik'],
                          styles['mt-1']
                        )}
                      >
                        Media
                      </p>
                      <img
                        src={data.ban.extra.media || placeholderUser}
                        alt={`ban${id}`}
                        className={classNames(styles.image, styles['mt-1'])}
                      />
                      <div className={styles['mt-1']}>
                        <Button color="danger" onClick={onDeleteBanMedia} size="sm">
                          Sil
                        </Button>
                      </div>
                    </>
                  )}
                  <p
                    className={classNames(
                      styles.text,
                      styles['fw-5'],
                      styles['f-rubik'],
                      styles['fs-2'],
                      styles['mt-2']
                    )}
                  >
                    Actions
                  </p>
                  {!isLoading &&
                    (data?.status === 'Open' ? (
                      <Button color="info" onClick={onCloseTicket}>
                        Ticketı Kapat
                      </Button>
                    ) : (
                      <Button color="info" onClick={onOpenTicket}>
                        Ticketı Aç
                      </Button>
                    ))}
                  {!isLoading &&
                    (data?.ban.active ? (
                      <Button color="secondary" className="ms-2" onClick={onDeactiveBan}>
                        Banı Kaldır
                      </Button>
                    ) : (
                      <Button color="secondary" className="ms-2" onClick={onActiveBan}>
                        Banla
                      </Button>
                    ))}
                  <div
                    className={cx(
                      styles['absolute-rt'],
                      styles['t-1'],
                      styles['r-1'],
                      {
                        'bg-red': data?.status === 'Open',
                        'bg-purple': data?.status === 'Closed'
                      },
                      styles['br-1'],
                      styles['pv-1'],
                      styles['ph-2']
                    )}
                  >
                    <p className={classNames(styles.text, styles['f-rubik'], styles['c-white'])}>
                      {data?.status}
                    </p>
                  </div>
                </div>
              </Container>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export const BanTicketDetail = memo(BanTicketDetailComponent)
