import React, { Suspense, useEffect, useState } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { classes } from '../data/layouts'
import Loader from '../layout/loader'
import Login from '../pages/authentication/login'
import { BanTicketDetail } from '../pages/ban-ticket-detail/BanTicketDetail'
import { BanTicket } from '../pages/ban-ticket/BanTicket'
import { BannedFakeUsers } from '../pages/BannedFakeUsers'
import Banneds from '../pages/Banneds'
import Chats from '../pages/Chats'
import EditNotification from '../pages/EditNotification'
import EnglishUsers from '../pages/EnglishUsers'
import ErrorPage from '../pages/errors/error404'
import { FakeUsers } from '../pages/FakeUsers'
import Home from '../pages/Home'
import { ImpressionStats } from '../pages/ImpressionStats'
import { LastRegisteredUsers } from '../pages/LastRegisteredUsers'
import { Like } from '../pages/Like'
import { PermissionStats } from '../pages/PermissionStats'
import PortugueseUsers from '../pages/PortugueseUsers'
import { PossibleFakeUsers } from '../pages/PossibleFakeUsers'
import ProfileTags from '../pages/ProfileTags'
import PushNotification from '../pages/PushNotification'
import Reports from '../pages/Reports'
import SavedNotification from '../pages/SavedNotification'
import SaveNotification from '../pages/SaveNotification'
import SearchTickets from '../pages/SearchTickets'
import SpanishUsers from '../pages/SpanishUsers'
import Tickets from '../pages/Tickets'
import Venus from '../pages/Venus'
import VerifyReqs from '../pages/VerifyReqs'
import { configureFakeBackend, authHeader, handleResponse } from '../services/fack.backend'
import PrivateRoute from './PrivateRoute'

// setup fake backend
configureFakeBackend()
const Routers = () => {
  const abortController = new AbortController()
  const [authenticated, setAuthenticated] = useState(false)
  const [accessKey, setAcessKey] = useState(false)

  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === 'compact-wrapper')
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop()

  useEffect(() => {
    const requestOptions = { method: 'GET', headers: authHeader() }

    fetch('/users', requestOptions).then(handleResponse)
    setAuthenticated(localStorage.getItem('authenticated'))
    setAcessKey(localStorage.getItem('accessToken'))
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed']
    console.disableYellowBox = true

    return function cleanup() {
      abortController.abort()
    }
  }, [abortController])

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<PrivateRoute />} />
        {authenticated || authenticated !== '' || authenticated !== undefined ? (
          <>
            <Route path="/index/Dubai" element={<Home />} />
            <Route path="/venus/Dubai" element={<Venus />} />
            <Route path="/banneds/Dubai" element={<Banneds />} />
            <Route path="/profile-tags/Dubai" element={<ProfileTags />} />
            <Route path="/search-ticket/Dubai" element={<SearchTickets />} />
            <Route path="/tickets/Dubai" element={<Tickets />} />
            <Route path="/reports/Dubai" element={<Reports />} />
            <Route path="/verification-requests/Dubai" element={<VerifyReqs />} />
            <Route path="/chats/Dubai" element={<Chats />} />
            <Route path="/push-notification/Dubai" element={<PushNotification />} />
            <Route path="/save-notification/Dubai" element={<SaveNotification />} />
            <Route path="/saved-notification/Dubai" element={<SavedNotification />} />
            <Route path="/edit-notification/Dubai:id" element={<EditNotification />} />
            <Route path="/english-users/Dubai" element={<EnglishUsers />} />
            <Route path="/spanish-users/Dubai" element={<SpanishUsers />} />
            <Route path="/portuguese-users/Dubai" element={<PortugueseUsers />} />
            <Route path="/ticket/ban" element={<BanTicket />} />
            <Route path="/ticket/ban/:id" element={<BanTicketDetail />} />
            <Route path="/last-registered-users/Dubai" element={<LastRegisteredUsers />} />
            <Route path="/possible-fake-users/Dubai" element={<PossibleFakeUsers />} />
            <Route path="/banned-fake-users/Dubai" element={<BannedFakeUsers />} />
            <Route path="/fake-users/Dubai" element={<FakeUsers />} />
            <Route path="/impression-stats/Dubai" element={<ImpressionStats />} />
            <Route path="/permission-stats/Dubai" element={<PermissionStats />} />
            <Route path="/send-like/Dubai" element={<Like />} />
            <Route path="/*" element={<ErrorPage />} />
          </>
        ) : (
          <Route path="/login" element={<Login />} />
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
    </Suspense>
  )
}

export default Routers
