import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Maximize } from "react-feather";
import { useNavigate } from "react-router-dom";
import { firebase_app } from "../../data/config";
import { useTranslation } from "react-i18next";
import { Admin, LogOut } from "../../constant";
import { classes } from "../../data/layouts";
import userLogo from "../../assets/images/user/user.png";
const Rightbar = () => {
  const history = useNavigate();
  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [moonlight, setMoonlight] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    setProfile(localStorage.getItem("profileURL") || man);
    setName(localStorage.getItem("Name"));
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
    i18n.changeLanguage("en");
  }, []);

  const LogoutAdmin = () => {
    localStorage.clear();
    history(`/login`);
  };

  const defaultLayoutObj = classes.find(
    (item) => Object.values(item).pop(1) === "compact-wrapper"
  );
  const layout =
    localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  const RedirectToChats = () => {
    history(`/app/users/userProfile/${layout}`);
  };
  const RedirectToChat = () => {
    history(`/app/chat-app/${layout}`);
  };

  const UserMenuRedirect = (redirect) => {
    history(redirect);
  };

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li className="maximize">
            <a className="text-dark" href="#javascript" onClick={goFull}>
              <Maximize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img className="b-r-10" src={userLogo} alt="" />
              <div className="media-body">
                <span>{"Karma Destek"}</span>
                <p className="mb-0 font-roboto">
                  {"Admin"} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={LogoutAdmin}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
// export default translate(Rightbar);
export default Rightbar;
