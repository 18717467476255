import React, { useState, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { Button, Card, CardHeader, Col, Table } from 'reactstrap'

import * as Requests from '../../utils/requests'

const NotificationTableForSaved = () => {
  const [savedNotifs, setSavedNotifs] = useState([])
  const [pendingPage, setPendingPage] = useState(1)
  const navigate = useNavigate()

  const getSavedNotifies = useMutation(Requests.getSavedNotifies, {
    onSuccess: async (response) => {
      setSavedNotifs(response.notifications)
    }
  })

  useEffect(() => {
    getSavedNotifies.mutate({ pendingPage })
  }, [])

  const editNotify = (id) => {
    navigate(`/edit-notification/Dubai:${id}`)
  }

  return (
    <Col sm="12">
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th scope="col">Tarih</th>
              <th scope="col">Başlık</th>
              <th scope="col">İçerik</th>
              <th scope="col">Tür</th>
              <th scope="col">Burç</th>
              <th scope="col">Gezegen</th>
              <th scope="col">Durum</th>
            </tr>
          </thead>
          <tbody>
            {savedNotifs.map((item) => (
              <tr key={item._id}>
                <td>{moment(item.publishDate).format('DD.MM.YYYY')}</td>
                <td>{item.title}</td>
                <td>{item.content}</td>
                <td>{item.contentType}</td>
                <td>{item.zodiacSign}</td>
                <td>{item.planet}</td>
                <td>{item.status}</td>
                <td>
                  <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Button color="warning" size="sm" onClick={() => editNotify(item._id)}>
                      <i className="icofont icofont-ui-edit" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  )
}

export default NotificationTableForSaved
