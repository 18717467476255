import React, { useEffect, Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import Knob from 'knob'
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap'

import ConfigDB from '../../data/customizer/config'
import * as Requests from '../../utils/requests'

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color

const KnobChartCustom = () => {
  const getUserCount = useMutation(Requests.getUserCount, {
    onSuccess: async (response) => {
      const totalUser = Knob({
        value: response,
        angleOffset: -125,
        angleArc: 250,
        thickness: 0.1,
        cursor: false,
        fgColor: primary,
        readOnly: true,
        bgColor: '#f6f7fb',
        lineCap: 'round',
        displayPrevious: false
      })

      document.getElementById('totalUser').appendChild(totalUser)
    }
  })

  const getSubCount = useMutation(Requests.getSubCount, {
    onSuccess: async (response) => {
      const totalSubs = Knob({
        value: response,
        angleOffset: -125,
        angleArc: 250,
        thickness: 0.1,
        cursor: false,
        fgColor: 'purple',
        readOnly: true,
        bgColor: '#f6f7fb',
        lineCap: 'round',
        displayPrevious: false
      })

      document.getElementById('totalSubs').appendChild(totalSubs)
    }
  })

  const getMeteroiteCount = useMutation(Requests.getMeteroiteCount, {
    onSuccess: async (response) => {
      const meteroitBuyAmount = Knob({
        value: response,
        angleOffset: -125,
        angleArc: 250,
        thickness: 0.1,
        cursor: false,
        fgColor: '#e500e5',
        readOnly: true,
        bgColor: '#f6f7fb',
        lineCap: 'round',
        displayPrevious: false
      })

      document.getElementById('meteroitBuyAmount').appendChild(meteroitBuyAmount)
    }
  })
  const getSubPurchaseCount = useMutation(Requests.getSubPurchaseCount, {
    onSuccess: async (response) => {
      const activeSubs = Knob({
        value: response,
        angleOffset: -125,
        angleArc: 250,
        thickness: 0.1,
        cursor: false,
        fgColor: '#50c878',
        readOnly: true,
        bgColor: '#f6f7fb',
        lineCap: 'round',
        displayPrevious: false
      })

      document.getElementById('activeSubs').appendChild(activeSubs)
    }
  })

  useEffect(async () => {
    getUserCount.mutate()
    getSubCount.mutate()
    getMeteroiteCount.mutate()
    getSubPurchaseCount.mutate()
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card style={{ width: '350px' }}>
            <CardHeader>
              <h5>Kayıtlı Kullanıcılar</h5>
            </CardHeader>
            <CardBody>
              <div className="knob-block text-center">
                <div className="knob" id="totalUser" />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '350px' }}>
            <CardHeader>
              <h5>Aboneler</h5>
            </CardHeader>
            <CardBody>
              <div className="knob-block text-center">
                <div className="knob" id="totalSubs" />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '350px' }}>
            <CardHeader>
              <h5>Meteor Satışı</h5>
            </CardHeader>
            <CardBody>
              <div className="knob-block text-center">
                <div className="knob" id="meteroitBuyAmount" />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ width: '350px' }}>
            <CardHeader>
              <h5>Aktif Abone</h5>
            </CardHeader>
            <CardBody>
              <div className="knob-block text-center">
                <div className="knob" id="activeSubs" />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default KnobChartCustom
