import React, { useEffect, useState } from 'react'

import { Navigate, Outlet, useNavigate } from 'react-router-dom'

import { firebase_app } from '../data/config'
import { authHeader, handleResponse } from '../services/fack.backend'

const PrivateRoute = () => {
  const [currentUser, setCurrentUser] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setAuthenticated(localStorage.getItem('authenticated'))
    localStorage.setItem('authenticated', authenticated)
    localStorage.setItem('currentUser', currentUser)
  }, [])

  return authenticated || authenticated !== '' || authenticated !== undefined ? (
    <Navigate exact to="/index/Dubai" />
  ) : (
    <Navigate exact to="/login" />
  )
}

export default PrivateRoute
