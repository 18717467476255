import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import moment from 'moment'
import { Button, Form, Image } from 'react-bootstrap'
import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

export const LikeRibbon = ({ user, onSend }) => (
  <Container fluid>
    <Row>
      <Col sm="14" xl="4">
        <Card className="ribbon-wrapper" style={{ width: '350px' }}>
          <CardBody>
            <img
              src={user.extra.profilePic}
              alt=""
              style={{
                width: '125px',
                height: '125px',
                marginBottom: '30px',
                borderRadius: '10px'
              }}
            />
            <h6 style={{ marginTop: '10px' }}>
              {user.name}, {moment().diff(user.extra.birthInfoAsDate, 'years')}
            </h6>
            <h6 style={{ marginTop: '10px' }}>{user.extra.location.country}</h6>
            <h6 style={{ marginTop: '10px' }}>{user.mail || user.phoneNumber}</h6>
            <h8 style={{ marginTop: '10px' }}>{user._id}</h8>
          </CardBody>

          <div className="d-flex justify-content-center col-sm p-3">
            <Button
              variant="success"
              style={{ marginBottom: '10px', width: '50%', marginRight: '10px' }}
              onClick={() => onSend(user._id)}
            >
              Gönder
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  </Container>
)
