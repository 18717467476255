import axios from 'axios'
import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane
} from 'reactstrap'
import { useMutation } from '@tanstack/react-query'
import * as Requests from '../../utils/requests'

const Logins = (props) => {
  const [searchParams] = useSearchParams()
  const [togglePassword, setTogglePassword] = useState(false)
  const [password, setPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [loginInforms, setLoginInforms] = useState({})
  const [error, setError] = useState('')

  const navigate = useNavigate()
  const handleChange = (e) => {
    setPassword(e.target.value)
  }
  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  const mutation = useMutation(Requests.login, {
    onSuccess: async (response) => {
      localStorage.setItem('accessToken', response.accessToken)
      localStorage.setItem('authenticated', true)

      loginRecordsMutate.mutate({
        ipAddress: panelLoginDto.ipAddress,
        location: panelLoginDto.location,
        user: panelLoginDto.userAgent,
        phoneNumber: phone
      })
    },
    onError: (e) => {
      setError('Invalid Phone number or Password !')
      console.log('e:', e)
    }
  })

  const loginRecordsMutate = useMutation(Requests.loginRecords, {
    onSuccess: async (response) => {
      const redirechPath = searchParams.get('redirect')

      if (redirechPath) return navigate(redirechPath)

      navigate(`/index/Dubai`)
    },
    onError: (e) => {
      setError('Invalid Phone number or Password !')
      console.log('e:', e)
    }
  })

  let panelLoginDto = {}

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }

  axios.get('https://www.geoplugin.net/json.gp', { headers }).then((response) => {
    setLoginInforms({
      ipAddress: response.data.geoplugin_request,
      location: `${response.data.geoplugin_city} / ${response.data.geoplugin_countryName}`
    })

    const userAgent = navigator.userAgent
    panelLoginDto = {
      ipAddress: loginInforms.ipAddress,
      location: loginInforms.location,
      userAgent
    }
  })

  const loginHandler = () => {
    mutation.mutate({ phoneNumber: phone, password })
  }

  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="/login">
                  <img
                    className="img-fluid for-light"
                    src={require('../../assets/images/logonew.png')}
                    alt="We are the best"
                  />
                  <img
                    className="img-fluid for-dark"
                    src={require('../../assets/images/logonew.png')}
                    alt="We are the best"
                  />
                  <h6>Karma Panel Ver 1.0</h6>
                </a>
              </div>
              <div className="login-main login-tab">
                <TabContent className="content-login">
                  <TabPane className="fade show">
                    <Form className="theme-form">
                      <h4>{'Sign in to account'}</h4>
                      <p>{'Enter your phone number & password to login'}</p>
                      <FormGroup>
                        <Label className="col-form-label">{'Phone number'}</Label>
                        <Input
                          className="form-control"
                          type="number"
                          required=""
                          placeholder="905xxxxxxxxx"
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </FormGroup>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{'Password'}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? 'text' : 'password'}
                          name="login[password]"
                          value={password}
                          onChange={(e) => handleChange(e)}
                          required=""
                          placeholder="*********"
                        />
                        <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
                          <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                      </div>
                      <div className="login-btn mb-0">
                        <Button className="button-sign-in" color="primary" onClick={loginHandler}>
                          {'Sign In'}
                        </Button>
                      </div>
                      {error && <p className="invalid-text">{error}</p>}
                    </Form>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Logins
