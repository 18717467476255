import React, { useState, useEffect } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { Button, Card, CardHeader, Col, Table } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

const NotificationTable = ({ waitingNotification }) => {
  const [pendingNotifs, setPendingNotifs] = useState([])
  const [pendingPage, setPendingPage] = useState(1)

  const getPendingNotifies = useMutation(Requests.getPendingNotifies, {
    onSuccess: async (response) => {
      setPendingNotifs(response.notifications)
    }
  })

  const approveNotifyMutate = useMutation(Requests.approveNotify, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const denyNotifyMutate = useMutation(Requests.denyNotify, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Günlük bildirim reddedildi.', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const navigate = useNavigate()

  useEffect(() => {
    getPendingNotifies.mutate({ pendingPage })
  }, [])

  const approveNotify = (id) => {
    approveNotifyMutate.mutate({ id })
  }

  const editNotify = (id) => {
    navigate(`/edit-notification/Dubai:${id}`)
  }

  const deleteNotify = (id) => {
    SweetAlert.fire({
      title: `Sil?`,
      text: 'Bildirimi silmeyi onaylıyor musun?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        denyNotifyMutate.mutate({ id })
      }
    })
  }

  return (
    <Col sm="12">
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th scope="col">Tarih</th>
              <th scope="col">Başlık</th>
              <th scope="col">İçerik</th>
              <th scope="col">Tür</th>
              <th scope="col">Burç</th>
              <th scope="col">Gezegen</th>
              <th scope="col">Durum</th>
            </tr>
          </thead>
          <tbody>
            {pendingNotifs.map((item) => (
              <tr key={item._id}>
                <td>{moment(item.publishDate).format('DD.MM.YYYY')}</td>
                <td>{item.title}</td>
                <td>{item.content}</td>
                <td>{item.contentType}</td>
                <td>{item.zodiacSign}</td>
                <td>{item.planet}</td>
                <td>{item.status}</td>
                <td>
                  <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Button color="primary" size="sm" onClick={() => approveNotify(item._id)}>
                      <i className="icofont icofont-ui-check" />
                    </Button>
                    <Button color="warning" size="sm" onClick={() => editNotify(item._id)}>
                      <i className="icofont icofont-ui-edit" />
                    </Button>
                    <Button color="danger" size="sm" onClick={() => deleteNotify(item._id)}>
                      <i className="icofont icofont-ui-delete" />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Col>
  )
}

export default NotificationTable
