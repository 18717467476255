import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'
import ModalNoInput from './ModalNoInput'

const RibbonsForOpenTicket = ({
  image,
  name,
  date,
  data,
  ticketID,
  message,
  allInform,
  tickets,
  setTickets = () => {}
}) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const [VaryingContentone, setVaryingContentone] = useState(false)
  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone)
  const [closeInfo, setCloseInfo] = useState('')
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [startChatModal, setStartChatModal] = useState(false)
  const [deleteAccModal, setDeleteAccModal] = useState(false)
  const [giveVenusModal, setGiveVenusModal] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const startChatMutate = useMutation(Requests.startChat, {
    onSuccess: async (response) => {
      setStartChatModal(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      setStartChatModal(false)
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const closeTicketMutate = useMutation(Requests.closeTicket, {
    onSuccess: async (response) => {
      setVaryingContentone(false)
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
      setTickets(tickets.filter((t) => t._id !== ticketID))
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
      setVaryingContentone(false)
    }
  })

  const givePremiumMutate = useMutation(Requests.updateUserWithAction, {
    onSuccess: async (response) => {
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const deleteAccountMutate = useMutation(Requests.deleteAccount, {
    onSuccess: async (response) => {
      closeTicket()
      toast.success(`Başarılı `, {
        position: toast.POSITION.TOP_RIGHT
      })
    },
    onError: (e) => {
      toast.error(`Hata Meydana Geldi ${e}`, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const startChat = () => {
    startChatMutate.mutate({ ticketID })
  }

  const sendCloseTicket = () => {
    closeTicketMutate.mutate({
      ticketID,
      operation: {
        info: closeInfo,
        date: new Date()
      }
    })
  }

  const givePremium = () => {
    setGiveVenusModal(false)
    givePremiumMutate.mutate({ userId: data?.creator?._id, action: 'premium' })
  }

  const deleteAccount = (phoneNumber) => {
    setDeleteAccModal(false)

    deleteAccountMutate.mutate(phoneNumber)
  }

  const closeTicket = () => {
    setVaryingContentone(true)
    setDropdownOpen(false)
  }

  const displayError = () => {
    setVerticalcenter(true)
    setDropdownOpen(false)
  }
  const giveVenusModalHandler = () => {
    setGiveVenusModal(true)
    setDropdownOpen(false)
  }
  const deleteAccountModalHandler = () => {
    setDeleteAccModal(true)
    setDropdownOpen(false)
  }

  const ticketText = (id) => {
    try {
      if (id === 0) {
        return JSON.parse(allInform?.registrationData?.deviceInfo)?.model
      }
      if (id === 1) {
        return JSON.parse(allInform?.registrationData?.deviceInfo)?.version
      }
      if (id === 2) {
        return JSON.parse(allInform?.registrationData?.deviceInfo)?.ipAdress
      }
      if (id === 3) {
        return JSON.parse(allInform?.registrationData?.deviceInfo)?.deviceId
      }
    } catch (e) {
      console.log(e)

      return 'Yok'
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '450px', minHeight: '475px' }}>
            <CardBody>
              <div className="ribbon ribbon-right">
                <Button onClick={closeTicket} size="sm">
                  <i className="icofont icofont-ui-close" />
                  {'  Kapat'}
                </Button>
              </div>
              <img
                src={image}
                alt=""
                style={{
                  width: '50px',
                  height: '50px',
                  borderRadius: '50px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              {allInform.creator?.profile?.bio && (
                <p>
                  <b>bio: </b>
                  {allInform.creator?.profile?.bio}
                </p>
              )}
              <p>{moment(date).utc(true).local(true).format('DD.MM.YYYY - HH:mm', true)}</p>
              <p>{message}</p>
              <div style={{ marginBottom: '30px' }}>
                <Button color="info" size="sm" onClick={displayError}>
                  Hatayı Göster
                </Button>
              </div>

              <div className="position-absolute" style={{ bottom: '25px' }}>
                <Button
                  className="btn-air-primary"
                  color="success"
                  onClick={() => setStartChatModal(true)}
                >
                  Chat Başlat
                </Button>
                <div className="btn-group dropup">
                  <button
                    type="button"
                    className="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={toggle}
                  >
                    işlemler
                  </button>
                  <div
                    className={`dropdown-menu${
                      dropdownOpen ? '-show' : ''
                    } position-absolute border w-100`}
                    style={{
                      bottom: '35px',
                      backgroundColor: 'white',
                      borderColor: 'gray',
                      borderWidth: '10px'
                    }}
                  >
                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={giveVenusModalHandler}
                    >
                      Premium Ver
                    </p>
                    <p
                      style={{
                        marginBottom: '3px',
                        marginTop: '0px',
                        cursor: 'pointer',
                        fontSize: '15px'
                      }}
                      className="dropdown-item"
                      onClick={deleteAccountModalHandler}
                    >
                      Hesabı Sil
                    </p>
                  </div>
                </div>
              </div>
              <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle} centered>
                <ModalHeader toggle={Verticalcentermodaltoggle}>Hata Detayı</ModalHeader>
                <ModalBody>
                  <h6>Kayıt Bilgileri</h6>
                  <></>
                  <p>{`Cihaz : ${ticketText(0)}`}</p>
                  <p>{`Version : ${ticketText(1)}`}</p>
                  <p>{`IP Adres : ${ticketText(2)}`}</p>
                  <p>{`Cihaz ID : ${ticketText(3)}`}</p>
                  <h6>Kayıt adımı</h6>
                  <p>{allInform.registrationStep || 'bilgi bulunmuyor'}</p>
                  <h6>Hata çıktısı</h6>
                  {allInform.errorData ? (
                    <p>{`\nMesaj : ${
                      allInform.errorData?.userProfile?.message || 'bilgi bulunmuyor'
                    }
                        }\n
                      Hata ekranı : ${
                        allInform.errorData?.userProfile?.screen || 'bilgi bulunmuyor'
                      }\n
                      Ayrıntılı Hata : ${
                        allInform.errorData?.userProfile?.error || 'bilgi bulunmuyor'
                      }
                      `}</p>
                  ) : (
                    <p>Bilgi bulunmuyor</p>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={Verticalcentermodaltoggle}>
                    Kapat
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={VaryingContentone} toggle={VaryingContentonetoggle}>
                <ModalHeader toggle={VaryingContentonetoggle}>Ticket Kapatma</ModalHeader>
                <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label className="col-form-label" for="message-text">
                        Mesaj
                      </Label>
                      <Input
                        type="textarea"
                        className="form-control"
                        id="message-text"
                        onChange={(e) => setCloseInfo(e.target.value)}
                      />
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={VaryingContentonetoggle}>
                    Vazgeç
                  </Button>
                  <Button color="primary" onClick={sendCloseTicket}>
                    Onayla
                  </Button>
                </ModalFooter>
              </Modal>
              <ModalNoInput
                desc="Kullanıcı ile chat başlatmak istiyor musunuz?"
                applyHandler={startChat}
                title="Chat Başlat"
                setIsOpen={setStartChatModal}
                isOpen={startChatModal}
              />
              <ModalNoInput
                desc="Kullanıcıya venüs üyeliği hediye eder"
                applyHandler={givePremium}
                title="Venüs Üyelik ver ?"
                setIsOpen={setGiveVenusModal}
                isOpen={giveVenusModal}
              />
              <ModalNoInput
                desc="Kullanıcı ait hesabı sonsuza kadar siler"
                applyHandler={() => deleteAccount(allInform.creator?.phoneNumber)}
                title="Hesabı Sil ?"
                setIsOpen={setDeleteAccModal}
                isOpen={deleteAccModal}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsForOpenTicket
