import React, { Fragment, useEffect, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'

import RibbonsCustomForBan from '../components/custom/RibbonsCustomForBan'
import Breadcrumbs from '../layout/breadcrumb'
import Footer from '../layout/footer'
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Taptop from '../layout/tap-top'
import * as Requests from '../utils/requests'

const AppLayout = ({ children, classNames, ...rest }) => {
  const [bannedUsers, setBannedUsers] = useState([])
  const [pagination, setPagination] = useState({
    next: {
      page: 1,
      limit: 20
    }
  })

  const mutation = useMutation(Requests.getBanneds, {
    onSuccess: async (response) => {
      setPagination(response.pagination)
      setBannedUsers(response.banneds)
    }
  })

  useEffect(() => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('accessToken')
    }

    mutation.mutate({
      nextPage: pagination.next.page,
      nextLimit: pagination.next.limit
    })
  }, [])

  return (
    <>
      {/* <Loader /> */}
      <Taptop />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            <div>
              <Breadcrumbs title="Banlı kullanıcılar" />
              <div className="row row-cols-auto">
                {bannedUsers.map((e) => (
                  <RibbonsCustomForBan
                    image={e.extra?.thumbnail || e.extra?.profilePic}
                    name={e.name}
                    duration={
                      moment(e.extra?.banDurationAsDate)
                        .utc(true)
                        .local(true)
                        .format('DD.MM.YYYY - HH:mm', true) || 'Süresiz'
                    }
                    phoneNumb={e.phoneNumber}
                    ticketID={e._id}
                    mail={e?.mail}
                  />
                ))}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AppLayout
