import React, { Fragment, useState } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import moment from 'moment'
import { Button, Form, Image } from 'react-bootstrap'
import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

export const FakeUsersRibbon = ({ user, mutation }) => {
  const [Verticalcenter, setVerticalcenter] = useState(false)
  const [VaryingContentone, setVaryingContentone] = useState(false)
  const [userInfosModal, setUserInfosModal] = useState(false)
  const [userInfo, setUserInfo] = useState({})

  const Verticalcentermodaltoggle = () => setVerticalcenter(!Verticalcenter)

  const userInfosModaltoggle = () => setUserInfosModal(!userInfosModal)
  const VaryingContentonetoggle = () => setVaryingContentone(!VaryingContentone)

  const [userName, setUserName] = useState(user.name)
  const [age, setAge] = useState(moment().diff(user.extra.birthInfoAsDate, 'years'))
  const [location, setLocation] = useState({
    country: user.extra.location.country,
    countryCode: user.extra.location.countryCode,
    provience: user.extra.location.provience
  })

  const removeAdminRoleMutate = useMutation(Requests.updateUserRole, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Kullanıcı Kaldırıldı.', 'success')
      mutation.mutate()
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const updateAdminUser = useMutation(Requests.updateAdminUser, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Kullanıcı Güncellendi.', 'success')
      setVerticalcenter(false)
      mutation.mutate()
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const getUserInfosMutation = useMutation(Requests.getFakeUserInfos, {
    onSuccess: async (response) => {
      setUserInfo(response)
    },
    onError: (e) => {}
  })

  const onUpdatePress = () => {
    setVerticalcenter(true)
  }
  const approveUpdateUser = () => {
    updateAdminUser.mutate({
      userName,
      birthDateAsInfo: moment().subtract(age, 'year').toDate(),
      location,
      userId: user._id
    })
  }

  const onRemovePress = () => {
    removeAdminRoleMutate.mutate({ userId: user._id, role: ['User'] })
  }

  const getUserInfos = () => {
    setUserInfosModal(true)
    getUserInfosMutation.mutate(user._id)
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '350px' }}>
            <CardBody>
              <Button
                variant="info"
                className="ribbon ribbon-right"
                style={{
                  marginRight: '10px'
                }}
                onClick={getUserInfos}
              >
                Ek Bilgiler
              </Button>

              <img
                src={user.extra.profilePic}
                alt=""
                style={{
                  width: '100px',
                  height: '100px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>
                {user.name}, {moment().diff(user.extra.birthInfoAsDate, 'years')}
              </h6>
              <h6 style={{ marginTop: '10px' }}>{user.extra.location.country}</h6>
              <h6 style={{ marginTop: '10px' }}>{user.mail || user.phoneNumber}</h6>
              <h8 style={{ marginTop: '10px' }}>{user._id}</h8>
            </CardBody>

            <div className="d-flex justify-content-center col-sm p-3">
              <Button
                variant="info"
                style={{ marginBottom: '10px', width: '50%', marginRight: '10px' }}
                onClick={onUpdatePress}
              >
                Güncelle
              </Button>
              <Button
                variant="danger"
                style={{ marginBottom: '10px', width: '50%' }}
                onClick={onRemovePress}
              >
                Kaldır
              </Button>
            </div>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={Verticalcenter} toggle={Verticalcentermodaltoggle}>
        <ModalHeader toggle={Verticalcentermodaltoggle}>Kullanıcı Bilgileri</ModalHeader>
        <ModalBody>
          <div>
            <Form.Label>İsim</Form.Label>
            <Form.Control
              id="userName"
              defaultValue={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
          <div>
            <Form.Label>Yaş</Form.Label>
            <Form.Control
              id="userAge"
              defaultValue={age}
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div>
            <Form.Label>Ülke</Form.Label>
            <Form.Control
              id="userCountry"
              defaultValue={location.country}
              onChange={(e) => setLocation((prev) => ({ ...prev, country: e.target.value }))}
            />
          </div>
          <div>
            <Form.Label>Ülke Kodu</Form.Label>
            <Form.Control
              id="userCountryCode"
              defaultValue={location.countryCode}
              onChange={(e) => setLocation((prev) => ({ ...prev, countryCode: e.target.value }))}
            />
          </div>
          <div>
            <Form.Label>Şehir</Form.Label>
            <Form.Control
              id="userCity"
              defaultValue={location.provience}
              onChange={(e) => setLocation((prev) => ({ ...prev, provience: e.target.value }))}
            />
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Button variant="success" style={{ width: '50%' }} onClick={approveUpdateUser}>
              Onayla
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={userInfosModal} toggle={userInfosModaltoggle}>
        <ModalHeader toggle={userInfosModaltoggle}>Ek Kullanıcı İstatistikleri</ModalHeader>
        <ModalBody>
          <h5>{`Gönderilen İstek Sayısı: ${userInfo.sentRequestCount}`}</h5>
          <h5>{`Onaylanan İstek Sayısı: ${userInfo.approvedRequestCount}`}</h5>
          <h5>{`Venüse dönüşen Sayısı: ${userInfo.venusConvertion}`}</h5>
          <h5>{`İmpression Atılma Tarihi: ${moment(userInfo.impressionDate)
            .utc(true)
            .format('DD.MM.yyyy HH:mm')}`}</h5>
          {userInfo.extraPhotos && userInfo.extraPhotos.length > 0 && <h5>Extra Fotolar</h5>}
          {userInfo.extraPhotos &&
            userInfo.extraPhotos.length > 0 &&
            userInfo.extraPhotos.map((photo) => (
              <Image
                src={photo}
                width={100}
                height={100}
                style={{ marginLeft: '20px', marginTop: '10px' }}
              />
            ))}
        </ModalBody>
      </Modal>
    </Container>
  )
}
