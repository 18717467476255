import React, { Fragment } from 'react'

import { Home } from 'react-feather'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap'

const Breadcrumbs = (props) => (
  <Container fluid>
    <div className="page-title">
      <Row>
        <Col xs="6">
          <h3>{props.title}</h3>
        </Col>
        <Col xs="6">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/index/Dubai">
                <Home />
              </Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>{props.title}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
    </div>
  </Container>
)

export default Breadcrumbs
