import axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CreateNotifyForm from "./CreateNotify";
import NotificationTable from "./NotificationTable";

const TabLineForSaveNotify = () => {
  const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState("1");

  useEffect(() => {}, []);

  return (
    <Fragment>
      <Container fluid={true} style={{ width: "95%" }}>
        <Row>
          <Col>
            <Card style={{ width: "90%" }}>
              <CardBody>
                <Nav className="border-tab nav-primary" tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={PrimarycolorLineTab === "1" ? "active" : ""}
                      onClick={() => setPrimarycolorLineTab("1")}
                    >
                      <i className="icofont icofont-plus"></i>
                      {"Bildirim Oluştur"}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={PrimarycolorLineTab === "2" ? "active" : ""}
                      onClick={() => setPrimarycolorLineTab("2")}
                    >
                      <i className="icofont icofont-wall-clock"></i>
                      {"Bekleyen Bildirimler"}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={PrimarycolorLineTab}>
                  <TabPane className="fade show" tabId="1">
                    <CreateNotifyForm />
                  </TabPane>
                  <TabPane tabId="2">
                    <NotificationTable />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TabLineForSaveNotify;
