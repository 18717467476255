import React, { Fragment, useState } from 'react'

import { Checkbox } from '@mobiscroll/react-lite'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { FormSelect } from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'
import ModalWithInput from './ModalWithInput'

const SendNotify = () => {
  const [header, setHeader] = useState('')
  const [content, setContent] = useState('')
  const [lang, setLang] = useState('tr-TR')
  const [approveNotify, setApproveNotify] = useState(false)
  const [input, setInput] = useState('')
  const languageHandle = (e) => {
    setLang(e.target.value)
  }

  const sendNotify = useMutation(Requests.sendNotify, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Bildirim Gönderildi', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const sendNotification = () => {
    axios.defaults.headers = {
      'Content-Type': 'application/json',
      authorization: localStorage.getItem('accessToken')
    }

    SweetAlert.fire({
      title: `Bildirimi Gönder?`,
      text: `${header} başlıklı bildirimin gönderilmesini onaylıyor musun?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        sendNotify.mutate({
          title: header,
          description: content,
          languageCode: lang
        })
      }
    })
  }

  const sendNotif = () => {
    if (input.toUpperCase() === 'evet'.toUpperCase()) {
      setApproveNotify(false)
      sendNotification()
    } else {
      setApproveNotify(false)
      SweetAlert.fire(`Hata Meydana geldi!`)
    }
  }

  const modalHandler = () => {
    setApproveNotify(true)
  }

  return (
    <Container fluid>
      <FormGroup>
        <div>
          <div className="row">
            <div className="col col-sm-col-md-1">
              <Label>Başlık</Label>
              <Input
                className="form-control btn-pill"
                id="header"
                onChange={(e) => setHeader(e.target.value)}
              />
            </div>
          </div>
          <div className="col-xxl-" style={{ marginTop: '20px' }}>
            <div>
              <Label>İçerik</Label>
              <Input
                type="textarea"
                className="form-control btn-pill-less"
                rows="3"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: '40px' }}>
            <div className="col col-xs">
              <Label>Dil</Label>
              <div className="col-">
                <Label for="edo-ani">
                  <Input
                    className="radio_animated"
                    id="daily"
                    type="radio"
                    name="rdo-ani"
                    defaultChecked
                    value="tr-TR"
                    onChange={(e) => languageHandle(e)}
                  />
                  {Option} Türkçe
                </Label>
                <div>
                  <Label for="edo-ani1">
                    <Input
                      className="radio_animated"
                      id="weekly"
                      type="radio"
                      name="rdo-ani"
                      value="en-EN"
                      onChange={(e) => languageHandle(e)}
                    />
                    {Option} English
                  </Label>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-grid gap-2"
            style={{
              marginTop: '40px',
              maxWidth: '100%'
            }}
          >
            <Button color="primary" style={{ alignSelf: 'center' }} onClick={modalHandler}>
              Bildirim Gönder
            </Button>
          </div>
        </div>
        <ModalWithInput
          setInput={setInput}
          isOpen={approveNotify}
          setIsOpen={setApproveNotify}
          title={`Bildirim göndermeyi onaylamak için "Evet" yazınız`}
          applyHandler={sendNotif}
        />
      </FormGroup>
    </Container>
  )
}

export default SendNotify
