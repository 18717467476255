import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 60 * 1000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

instance.interceptors.request.use(async (options) => {
  const accessToken = localStorage.getItem('accessToken')

  if (accessToken) {
    options.headers.authorization = accessToken
  }

  return options
})

export default instance

export const taskInstance = axios.create({
  baseURL: process.env.REACT_APP_TASK_API,
  timeout: 60 * 1000
})

taskInstance.interceptors.request.use(async (options) => {
  const accessToken = localStorage.getItem('accessToken')

  if (accessToken) {
    options.headers.authorization = accessToken
  }

  return options
})
