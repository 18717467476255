import React, { Fragment } from 'react'

import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import SweetAlert from 'sweetalert2'

import * as Requests from '../../utils/requests'

const RibbonsCustom = ({ image, name, meteorite, type, userID, phone }) => {
  const removeVenusMutate = useMutation(Requests.removeVenus, {
    onSuccess: async (response) => {
      SweetAlert.fire('Başarılı!', 'Üyelik sonlandırıldı.', 'success')
    },
    onError: (e) => {
      SweetAlert.fire(`Hata Meydana geldi ${e}`)
    }
  })

  const removeVenus = () => {
    SweetAlert.fire({
      title: `${name} adlı kullanıcının Venüs'ünü kaldır?`,
      text: 'Kullanıcıya ait venüs üyeliği sonlandırır.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'cancel',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        removeVenusMutate.mutate({ userID: userID || phone })
      }
    })
  }

  return (
    <Container fluid>
      <Row>
        <Col sm="14" xl="4">
          <Card className="ribbon-wrapper" style={{ width: '350px' }}>
            <CardBody>
              <div
                className="ribbon ribbon-info ribbon-right"
                style={{ borderRadius: '10px', marginRight: '10px' }}
              >
                {type}
              </div>
              <img
                src={image}
                alt=""
                style={{
                  width: '45px',
                  height: '45px',
                  borderRadius: '45px',
                  marginBottom: '30px'
                }}
              />
              <h6 style={{ marginTop: '10px' }}>{name}</h6>
              <p>{`Meteorite : ${meteorite}`}</p>
              <Button className="btn-air-primary" color="primary" onClick={removeVenus}>
                Premiumu Kaldır
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default RibbonsCustom
